import { combineReducers } from 'redux';
import {
    ASSET_FETCH_SUCCESS,
    ASSET_TITLE_SET,
    ASSET_UPLOAD_CANCEL_SET,
    ASSET_UPLOAD_ERROR,
    ASSET_UPLOAD_IN_PROGRESS,
    ASSET_UPLOAD_PROGRESS_SET,
    ASSET_UPLOAD_SET,
    ASSET_UPLOAD_SUCCESS,
    CATEGORY_SET,
    DESCRIPTION_SET,
    EMPTY_VALUES_SET,
    SOURCE_TYPE_SET,
    TAGS_FETCH_ERROR,
    TAGS_FETCH_IN_PROGRESS,
    TAGS_FETCH_SUCCESS,
    TAGS_SET,
    TWEET_ID_SET,
    UPLOAD_FILE_CANCEL_SET,
    UPLOAD_FILE_ERROR_MESSAGE_SET,
    UPLOAD_FILES_DIALOG_HIDE,
    UPLOAD_FILES_DIALOG_SHOW,
} from '../../constants/createAssets';
import {
    CATEGORY_ADD_ERROR,
    CATEGORY_ADD_IN_PROGRESS,
    CATEGORY_ADD_SUCCESS,
    CATEGORY_FETCH_ERROR,
    CATEGORY_FETCH_IN_PROGRESS,
    CATEGORY_FETCH_SUCCESS,
} from '../../constants/createAssets/category';
import {
    SOURCE_TYPE_FETCH_ERROR,
    SOURCE_TYPE_FETCH_IN_PROGRESS,
    SOURCE_TYPE_FETCH_SUCCESS,
} from '../../constants/createAssets/sourceType';

const assetTitle = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case ASSET_TITLE_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case ASSET_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value.name,
            valid: true,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const sourceType = (state = {
    value: null,
    options: [],
    inProgress: false,
    valid: true,
}, action) => {
    switch (action.type) {
    case SOURCE_TYPE_SET:
        return {
            ...state,
            value: action.value,
            valid: action.valid,
        };
    case SOURCE_TYPE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SOURCE_TYPE_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            options: action.value,
        };
    case SOURCE_TYPE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case ASSET_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value.source && action.value.source.type,
            valid: true,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            value: null,
        };
    default:
        return state;
    }
};

const tweetID = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case TWEET_ID_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case ASSET_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value.source && action.value.source.id,
            valid: true,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const category = (state = {
    value: null,
    options: [],
    inProgress: false,
    valid: true,
}, action) => {
    switch (action.type) {
    case CATEGORY_SET:
        return {
            ...state,
            value: action.value,
            valid: action.valid,
        };
    case CATEGORY_FETCH_IN_PROGRESS:
    case CATEGORY_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CATEGORY_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            options: action.value,
        };
    case CATEGORY_FETCH_ERROR:
    case CATEGORY_ADD_ERROR:
    case CATEGORY_ADD_SUCCESS:
        return {
            ...state,
            inProgress: false,
        };
    case ASSET_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value.category,
            valid: true,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            value: null,
        };
    default:
        return state;
    }
};

const description = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case ASSET_FETCH_SUCCESS:
        return {
            value: action.value && action.value.description ? action.value.description : '',
            valid: true,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const tags = (state = {
    value: [],
    options: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case TAGS_SET:
        return {
            ...state,
            value: action.value,
        };
    case TAGS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TAGS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            options: action.value,
        };
    case TAGS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case ASSET_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value.tags ? action.value.tags : [],
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            value: [],
        };
    default:
        return state;
    }
};

const uploadAsset = (state = {
    file: null,
    valid: false,
    inProgress: false,
    progress: [],
    list: [],
    cancel: {},
    successList: [],
}, action) => {
    switch (action.type) {
    case ASSET_UPLOAD_SET:
        return {
            ...state,
            file: action.value,
            list: action.list,
            valid: false,
        };
    case ASSET_FETCH_SUCCESS:
        return {
            ...state,
            file: action.value && action.value.file ? { file: action.value.file } : state.file,
        };
    case UPLOAD_FILE_ERROR_MESSAGE_SET:
        return {
            ...state,
            valid: true,
        };
    case ASSET_UPLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_UPLOAD_SUCCESS:
        return {
            ...state,
            inProgress: false,
            successList: [...state.successList, action.id],
        };
    case ASSET_UPLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case ASSET_UPLOAD_PROGRESS_SET: {
        let array = [...state.progress];
        const obj = {
            id: action.ID,
            progress: action.value,
        };
        const find = array && array.length && array.find((val) => val.id === action.ID);
        if (find) {
            array = array.map((val) => val.id !== obj.id ? val : obj);
        } else {
            array.push(obj);
        }

        return {
            ...state,
            progress: [...array],
        };
    }
    case ASSET_UPLOAD_CANCEL_SET: {
        const obj = {
            [action.ID]: action.cancel,
        };

        return {
            ...state,
            cancel: obj,
        };
    }
    case UPLOAD_FILE_CANCEL_SET:
        return {
            ...state,
            file: null,
            list: action.list,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            file: null,
        };
    default:
        return state;
    }
};

const uploadFilesDialog = (state = false, action) => {
    switch (action.type) {
    case UPLOAD_FILES_DIALOG_SHOW:
        return true;
    case UPLOAD_FILES_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    assetTitle,
    sourceType,
    tweetID,
    category,
    description,
    tags,
    uploadAsset,
    uploadFilesDialog,
});
