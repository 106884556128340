import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';
import {
    ADD_MEDIA_NODE_DIALOG_HIDE,
    ADD_MEDIA_NODE_DIALOG_SHOW,
    ADD_MEDIA_NODE_ERROR,
    ADD_MEDIA_NODE_IN_PROGRESS,
    ADD_MEDIA_NODE_SUCCESS,
    CONNECT_MEDIA_NODE_ERROR,
    CONNECT_MEDIA_NODE_IN_PROGRESS,
    CONNECT_MEDIA_NODE_SUCCESS,
    DELETE_MEDIA_NODE_DIALOG_HIDE,
    DELETE_MEDIA_NODE_DIALOG_SHOW,
    DELETE_MEDIA_NODE_ERROR,
    DELETE_MEDIA_NODE_IN_PROGRESS,
    DELETE_MEDIA_NODE_SUCCESS,
    MEDIA_NODE_ACCESS_TOKEN_FETCH_ERROR,
    MEDIA_NODE_ACCESS_TOKEN_FETCH_IN_PROGRESS,
    MEDIA_NODE_ACCESS_TOKEN_FETCH_SUCCESS,
    MEDIA_NODE_AUTH_TOKEN_SET,
    MEDIA_NODE_DESCRIPTION_SET,
    MEDIA_NODE_DETAILS_FETCH_ERROR,
    MEDIA_NODE_DETAILS_FETCH_IN_PROGRESS,
    MEDIA_NODE_DETAILS_FETCH_SUCCESS,
    MEDIA_NODE_DETAILS_SET,
    MEDIA_NODE_NAME_SET,
    MEDIA_NODE_PROFILE_DETAILS_FETCH_ERROR,
    MEDIA_NODE_PROFILE_DETAILS_FETCH_IN_PROGRESS,
    MEDIA_NODE_PROFILE_DETAILS_FETCH_SUCCESS,
    MEDIA_NODE_STATUS_FETCH_ERROR,
    MEDIA_NODE_STATUS_FETCH_IN_PROGRESS,
    MEDIA_NODE_STATUS_FETCH_SUCCESS,
    MEDIA_NODE_UPLOAD_ACCESS_TOKEN_SET,
    MEDIA_NODE_URL_SET,
    MEDIA_NODES_FETCH_ERROR,
    MEDIA_NODES_FETCH_IN_PROGRESS,
    MEDIA_NODES_FETCH_SUCCESS,
    UPDATE_MEDIA_NODE_DIALOG_HIDE,
    UPDATE_MEDIA_NODE_DIALOG_SHOW,
    UPDATE_MEDIA_NODE_ERROR,
    UPDATE_MEDIA_NODE_IN_PROGRESS,
    UPDATE_MEDIA_NODE_SUCCESS,
    VERIFY_MEDIA_NODE_ERROR,
    VERIFY_MEDIA_NODE_IN_PROGRESS,
    VERIFY_MEDIA_NODE_SUCCESS,
} from '../../constants/mediaNodes';
import { combineReducers } from 'redux';
import {
    AMINO_SIGN_SUCCESS,
    CONNECT_ACCOUNT_ERROR,
    CONNECT_ACCOUNT_IN_PROGRESS,
    CONNECT_ACCOUNT_SUCCESS,
    CONNECT_COSMO_STATION_ERROR,
    CONNECT_COSMO_STATION_IN_PROGRESS,
    CONNECT_COSMO_STATION_SUCCESS,
    CONNECT_LEAP_ACCOUNT_ERROR,
    CONNECT_LEAP_ACCOUNT_IN_PROGRESS,
    CONNECT_LEAP_ACCOUNT_SUCCESS,
    CONNECT_WALLET_TYPE_SET,
    DISCONNECT_SET,
    KEPLR_ACCOUNT_KEYS_SET,
} from '../../constants/account';
import _liveStream from './liveStream';

const addMediaNodeDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case ADD_MEDIA_NODE_DIALOG_SHOW:
        return {
            open: true,
        };
    case ADD_MEDIA_NODE_DIALOG_HIDE:
    case ADD_MEDIA_NODE_SUCCESS:
        return {
            open: false,
        };
    default:
        return state;
    }
};

const updateMediaNodeDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case UPDATE_MEDIA_NODE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case UPDATE_MEDIA_NODE_DIALOG_HIDE:
    case UPDATE_MEDIA_NODE_SUCCESS:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const deleteMediaNodeDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DELETE_MEDIA_NODE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case DELETE_MEDIA_NODE_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const mediaNodesList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case MEDIA_NODES_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MEDIA_NODES_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case MEDIA_NODES_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const mediaNodeDetails = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case MEDIA_NODE_DETAILS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MEDIA_NODE_DETAILS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case MEDIA_NODE_DETAILS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case MEDIA_NODE_DETAILS_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const mediaNodeAdd = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ADD_MEDIA_NODE_IN_PROGRESS:
    case UPDATE_MEDIA_NODE_IN_PROGRESS:
    case DELETE_MEDIA_NODE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ADD_MEDIA_NODE_SUCCESS:
    case UPDATE_MEDIA_NODE_SUCCESS:
    case DELETE_MEDIA_NODE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ADD_MEDIA_NODE_ERROR:
    case UPDATE_MEDIA_NODE_ERROR:
    case DELETE_MEDIA_NODE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const mediaNodeName = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MEDIA_NODE_NAME_SET:
        return {
            value: action.value,
        };

    case ADD_MEDIA_NODE_DIALOG_HIDE:
    case UPDATE_MEDIA_NODE_DIALOG_HIDE:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const mediaNodeURL = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MEDIA_NODE_URL_SET:
        return {
            value: action.value,
        };

    case ADD_MEDIA_NODE_DIALOG_HIDE:
    case UPDATE_MEDIA_NODE_DIALOG_HIDE:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const mediaNodeDescription = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case MEDIA_NODE_DESCRIPTION_SET:
        return {
            value: action.value,
            valid: action.valid,
        };

    case ADD_MEDIA_NODE_DIALOG_HIDE:
    case UPDATE_MEDIA_NODE_DIALOG_HIDE:
        return {
            value: '',
            valid: true,
        };

    default:
        return state;
    }
};

const mediaNodeAuthToken = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MEDIA_NODE_AUTH_TOKEN_SET:
        return {
            value: action.value,
        };

    case ADD_MEDIA_NODE_DIALOG_HIDE:
    case UPDATE_MEDIA_NODE_DIALOG_HIDE:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const mediaNodeAccessToken = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MEDIA_NODE_UPLOAD_ACCESS_TOKEN_SET:
        return {
            value: action.value,
        };

    case ADD_MEDIA_NODE_DIALOG_HIDE:
    case UPDATE_MEDIA_NODE_DIALOG_HIDE:
        return {
            value: '',
        };

    default:
        return state;
    }
};

const connection = (state = {
    inProgress: false,
    address: '',
    addressInProgress: true,
    keys: {},
    walletType: null,
}, action) => {
    switch (action.type) {
    case CONNECT_ACCOUNT_IN_PROGRESS:
    case CONNECT_COSMO_STATION_IN_PROGRESS:
    case CONNECT_LEAP_ACCOUNT_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            addressInProgress: true,
        };
    case CONNECT_MEDIA_NODE_IN_PROGRESS:
    case VERIFY_MEDIA_NODE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CONNECT_ACCOUNT_SUCCESS:
    case CONNECT_LEAP_ACCOUNT_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.length &&
                action.value[0] && action.value[0].address,
        };
    case CONNECT_COSMO_STATION_SUCCESS:
        return {
            ...state,
            addressInProgress: false,
            inProgress: false,
            address: action.value && action.value.address,
            keys: action.value && action.value.address ? {
                ...action.value,
                bech32Address: action.value.address,
            } : {},
        };
    case CONNECT_ACCOUNT_ERROR:
    case CONNECT_COSMO_STATION_ERROR:
    case CONNECT_LEAP_ACCOUNT_ERROR:
    case AMINO_SIGN_SUCCESS:
        return {
            ...state,
            inProgress: false,
            addressInProgress: false,
        };
    case CONNECT_MEDIA_NODE_SUCCESS:
    case CONNECT_MEDIA_NODE_ERROR:
    case VERIFY_MEDIA_NODE_ERROR:
    case VERIFY_MEDIA_NODE_SUCCESS:
        return {
            ...state,
            inProgress: false,
        };
    case KEPLR_ACCOUNT_KEYS_SET:
        return {
            ...state,
            keys: action.value,
        };
    case CONNECT_WALLET_TYPE_SET:
        return {
            ...state,
            walletType: action.value,
        };
    default:
        return state;
    }
};

const profileDetails = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MEDIA_NODE_PROFILE_DETAILS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MEDIA_NODE_PROFILE_DETAILS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case MEDIA_NODE_PROFILE_DETAILS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
    case CONNECT_MEDIA_NODE_IN_PROGRESS:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const token = (state = {
    inProgress: false,
    value: '',
    url: '',
    id: '',
}, action) => {
    switch (action.type) {
    case MEDIA_NODE_ACCESS_TOKEN_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MEDIA_NODE_ACCESS_TOKEN_FETCH_SUCCESS:
    case VERIFY_MEDIA_NODE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value && action.value.access_token,
        };
    case MEDIA_NODE_DETAILS_FETCH_SUCCESS: {
        // let value = '';
        // if (action.value && action.value._id) {
        //     value = localStorage.getItem(`acToken_of_studio_media_node_${action.value._id}`);
        // }

        return {
            ...state,
            // value: value,
            url: action.value && action.value.url,
            id: action.value && action.value._id,
        };
    }
    case MEDIA_NODE_ACCESS_TOKEN_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const status = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MEDIA_NODE_STATUS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MEDIA_NODE_STATUS_FETCH_SUCCESS: {
        const obj = { ...state.value };
        if (action.nodeID && action.nodeID) {
            obj[action.nodeID] = action.value;
        }

        return {
            inProgress: false,
            value: { ...obj },
        };
    }
    case MEDIA_NODE_STATUS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    addMediaNodeDialog,
    updateMediaNodeDialog,
    deleteMediaNodeDialog,

    mediaNodesList,
    mediaNodeDetails,
    mediaNodeAdd,

    mediaNodeName,
    mediaNodeURL,
    mediaNodeDescription,
    mediaNodeAuthToken,
    mediaNodeAccessToken,

    connection,
    profileDetails,
    token,
    status,
    liveStream: _liveStream,
});
