export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_IN_PROGRESS = 'FETCH_CHANNELS_IN_PROGRESS';
export const FETCH_CHANNELS_ERROR = 'FETCH_CHANNELS_ERROR';

export const FETCH_CHANNEL_INFO_SUCCESS = 'FETCH_CHANNEL_INFO_SUCCESS';
export const FETCH_CHANNEL_INFO_ERROR = 'FETCH_CHANNEL_INFO_ERROR';
export const FETCH_CHANNEL_INFO_IN_PROGRESS = 'FETCH_CHANNEL_INFO_IN_PROGRESS';

export const FETCH_CHANNEL_IV_SUCCESS = 'FETCH_CHANNEL_IV_SUCCESS';
export const FETCH_CHANNEL_IV_ERROR = 'FETCH_CHANNEL_IV_ERROR';
export const FETCH_CHANNEL_IV_IN_PROGRESS = 'FETCH_CHANNEL_IV_IN_PROGRESS';

export const CHANNEL_ID_SET = 'CHANNEL_ID_SET';
export const CREATE_CHANNEL_DIALOG_SHOW = 'CREATE_CHANNEL_DIALOG_SHOW';
export const CREATE_CHANNEL_DIALOG_HIDE = 'CREATE_CHANNEL_DIALOG_HIDE';

export const CHANNEL_INFO_IV_TAB_SET = 'CHANNEL_INFO_IV_TAB_SET';
export const CHANNEL_INFO_IV_SORT_VALUE_SET = 'CHANNEL_INFO_IV_SORT_VALUE_SET';

export const SETTINGS_CHANNEL_IMAGE_SET = 'SETTINGS_CHANNEL_IMAGE_SET';
export const SETTINGS_CHANNEL_NAME_SET = 'SETTINGS_CHANNEL_NAME_SET';
export const SETTINGS_CHANNEL_DESCRIPTION_SET = 'SETTINGS_CHANNEL_DESCRIPTION_SET';
export const SETTINGS_CHANNEL_ADDRESS_SET = 'SETTINGS_CHANNEL_ADDRESS_SET';
export const SETTINGS_CHANNEL_ALLOW_PUBLISHERS_SET = 'SETTINGS_CHANNEL_ALLOW_PUBLISHERS_SET';

export const FETCH_CHANNEL_UPDATE_SUCCESS = 'FETCH_CHANNEL_UPDATE_SUCCESS';
export const FETCH_CHANNEL_UPDATE_ERROR = 'FETCH_CHANNEL_UPDATE_ERROR';
export const FETCH_CHANNEL_UPDATE_IN_PROGRESS = 'FETCH_CHANNEL_UPDATE_IN_PROGRESS';

export const CHANNEL_USER_NAME_SET = 'CHANNEL_USER_NAME_SET';
export const CHANNEL_USER_NAME_STATUS_SET = 'CHANNEL_USER_NAME_STATUS_SET';

export const CHANNEL_ADD_IN_PROGRESS = 'CHANNEL_ADD_IN_PROGRESS';
export const CHANNEL_ADD_SUCCESS = 'CHANNEL_ADD_SUCCESS';
export const CHANNEL_ADD_ERROR = 'CHANNEL_ADD_ERROR';

export const CHANNEL_UPDATE_IN_PROGRESS = 'CHANNEL_UPDATE_IN_PROGRESS';
export const CHANNEL_UPDATE_SUCCESS = 'CHANNEL_UPDATE_SUCCESS';
export const CHANNEL_UPDATE_ERROR = 'CHANNEL_UPDATE_ERROR';

export const CHANNEL_CHANGE_DIALOG_SHOW = 'CHANNEL_CHANGE_DIALOG_SHOW';
export const CHANNEL_CHANGE_DIALOG_HIDE = 'CHANNEL_CHANGE_DIALOG_HIDE';

export const FETCH_CHANNEL_AVAILABILITY_SUCCESS = 'FETCH_CHANNEL_AVAILABILITY_SUCCESS';
export const FETCH_CHANNELS_AVAILABILITY_IN_PROGRESS = 'FETCH_CHANNELS_AVAILABILITY_IN_PROGRESS';
export const FETCH_CHANNELS_AVAILABILITY_ERROR = 'FETCH_CHANNELS_AVAILABILITY_ERROR';

export const CHANNELS_SETTINGS_TAB_SET = 'CHANNELS_SETTINGS_TAB_SET';
export const CHANNEL_NAME_SET = 'CHANNEL_NAME_SET';
export const CHANNEL_USERNAME_SETTINGS_SET = 'CHANNEL_USERNAME_SETTINGS_SET';
export const CHANNEL_DESCRIPTION_SET = 'CHANNEL_DESCRIPTION_SET';

export const SAVE_BUTTON_ENABLE_SET = 'SAVE_BUTTON_ENABLE_SET';
