export const MEDIA_NODES_FETCH_SUCCESS = 'MEDIA_NODES_FETCH_SUCCESS';
export const MEDIA_NODES_FETCH_IN_PROGRESS = 'MEDIA_NODES_FETCH_IN_PROGRESS';
export const MEDIA_NODES_FETCH_ERROR = 'MEDIA_NODES_FETCH_ERROR';

export const MEDIA_NODE_DETAILS_FETCH_SUCCESS = 'MEDIA_NODE_DETAILS_FETCH_SUCCESS';
export const MEDIA_NODE_DETAILS_FETCH_IN_PROGRESS = 'MEDIA_NODE_DETAILS_FETCH_IN_PROGRESS';
export const MEDIA_NODE_DETAILS_FETCH_ERROR = 'MEDIA_NODE_DETAILS_FETCH_ERROR';
export const MEDIA_NODE_DETAILS_SET = 'MEDIA_NODE_DETAILS_SET';

export const ADD_MEDIA_NODE_SUCCESS = 'ADD_MEDIA_NODE_SUCCESS';
export const ADD_MEDIA_NODE_IN_PROGRESS = 'ADD_MEDIA_NODE_IN_PROGRESS';
export const ADD_MEDIA_NODE_ERROR = 'ADD_MEDIA_NODE_ERROR';

export const UPDATE_MEDIA_NODE_SUCCESS = 'UPDATE_MEDIA_NODE_SUCCESS';
export const UPDATE_MEDIA_NODE_IN_PROGRESS = 'UPDATE_MEDIA_NODE_IN_PROGRESS';
export const UPDATE_MEDIA_NODE_ERROR = 'UPDATE_MEDIA_NODE_ERROR';

export const DELETE_MEDIA_NODE_SUCCESS = 'DELETE_MEDIA_NODE_SUCCESS';
export const DELETE_MEDIA_NODE_IN_PROGRESS = 'DELETE_MEDIA_NODE_IN_PROGRESS';
export const DELETE_MEDIA_NODE_ERROR = 'DELETE_MEDIA_NODE_ERROR';

export const ADD_MEDIA_NODE_DIALOG_SHOW = 'ADD_MEDIA_NODE_DIALOG_SHOW';
export const ADD_MEDIA_NODE_DIALOG_HIDE = 'ADD_MEDIA_NODE_DIALOG_HIDE';
export const UPDATE_MEDIA_NODE_DIALOG_SHOW = 'UPDATE_MEDIA_NODE_DIALOG_SHOW';
export const UPDATE_MEDIA_NODE_DIALOG_HIDE = 'UPDATE_MEDIA_NODE_DIALOG_HIDE';
export const DELETE_MEDIA_NODE_DIALOG_SHOW = 'DELETE_MEDIA_NODE_DIALOG_SHOW';
export const DELETE_MEDIA_NODE_DIALOG_HIDE = 'DELETE_MEDIA_NODE_DIALOG_HIDE';

export const MEDIA_NODE_NAME_SET = 'MEDIA_NODE_NAME_SET';
export const MEDIA_NODE_DESCRIPTION_SET = 'MEDIA_NODE_DESCRIPTION_SET';
export const MEDIA_NODE_URL_SET = 'MEDIA_NODE_URL_SET';
export const MEDIA_NODE_AUTH_TOKEN_SET = 'MEDIA_NODE_AUTH_TOKEN_SET';
export const MEDIA_NODE_UPLOAD_ACCESS_TOKEN_SET = 'MEDIA_NODE_UPLOAD_ACCESS_TOKEN_SET';

export const CONNECT_MEDIA_NODE_IN_PROGRESS = 'CONNECT_MEDIA_NODE_IN_PROGRESS';
export const CONNECT_MEDIA_NODE_SUCCESS = 'CONNECT_MEDIA_NODE_SUCCESS';
export const CONNECT_MEDIA_NODE_ERROR = 'CONNECT_MEDIA_NODE_ERROR';

export const VERIFY_MEDIA_NODE_IN_PROGRESS = 'VERIFY_MEDIA_NODE_IN_PROGRESS';
export const VERIFY_MEDIA_NODE_SUCCESS = 'VERIFY_MEDIA_NODE_SUCCESS';
export const VERIFY_MEDIA_NODE_ERROR = 'VERIFY_MEDIA_NODE_ERROR';

export const MEDIA_NODE_PROFILE_DETAILS_FETCH_IN_PROGRESS = 'MEDIA_NODE_PROFILE_DETAILS_FETCH_IN_PROGRESS';
export const MEDIA_NODE_PROFILE_DETAILS_FETCH_SUCCESS = 'MEDIA_NODE_PROFILE_DETAILS_FETCH_SUCCESS';
export const MEDIA_NODE_PROFILE_DETAILS_FETCH_ERROR = 'MEDIA_NODE_PROFILE_DETAILS_FETCH_ERROR';

export const MEDIA_NODE_ACCESS_TOKEN_FETCH_IN_PROGRESS = 'MEDIA_NODE_ACCESS_TOKEN_FETCH_IN_PROGRESS';
export const MEDIA_NODE_ACCESS_TOKEN_FETCH_SUCCESS = 'MEDIA_NODE_ACCESS_TOKEN_FETCH_SUCCESS';
export const MEDIA_NODE_ACCESS_TOKEN_FETCH_ERROR = 'MEDIA_NODE_ACCESS_TOKEN_FETCH_ERROR';

export const MEDIA_NODE_STATUS_FETCH_IN_PROGRESS = 'MEDIA_NODE_STATUS_FETCH_IN_PROGRESS';
export const MEDIA_NODE_STATUS_FETCH_SUCCESS = 'MEDIA_NODE_STATUS_FETCH_SUCCESS';
export const MEDIA_NODE_STATUS_FETCH_ERROR = 'MEDIA_NODE_STATUS_FETCH_ERROR';
