import {
    CONTRACTS_LIST_SEARCH_VALUE_SET,
    FETCH_CONTRACTS_LIST_ERROR,
    FETCH_CONTRACTS_LIST_IN_PROGRESS,
    FETCH_CONTRACTS_LIST_SUCCESS,
} from '../constants/contractsList';
import { combineReducers } from 'redux';
import { DEFAULT_LIMIT, DEFAULT_SEARCH, DEFAULT_SKIP } from '../config';

const contractsList = (state = {
    list: [],
    inProgress: false,
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case FETCH_CONTRACTS_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_CONTRACTS_LIST_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.value,
            skip: action.skip,
            limit: action.limit,
            search: action.search,
            total: action.total,
        };
    case FETCH_CONTRACTS_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const contractsListSearch = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case CONTRACTS_LIST_SEARCH_VALUE_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

export default combineReducers({
    contractsList,
    contractsListSearch,
});
