export const QUERY_CHANNEL_COLLECTION_SUCCESS = 'QUERY_CHANNEL_COLLECTION_SUCCESS';
export const QUERY_CHANNEL_COLLECTION_ERROR = 'QUERY_CHANNEL_COLLECTION_ERROR';
export const QUERY_CHANNEL_COLLECTION_IN_PROGRESS = 'QUERY_CHANNEL_COLLECTION_IN_PROGRESS';

export const QUERY_CHANNEL_TOKEN_DETAILS_SUCCESS = 'QUERY_CHANNEL_TOKEN_DETAILS_SUCCESS';
export const QUERY_CHANNEL_TOKEN_DETAILS_ERROR = 'QUERY_CHANNEL_TOKEN_DETAILS_ERROR';
export const QUERY_CHANNEL_TOKEN_DETAILS_IN_PROGRESS = 'QUERY_CHANNEL_TOKEN_DETAILS_IN_PROGRESS';

export const QUERY_CHANNEL_CONFIG_SUCCESS = 'QUERY_CHANNEL_CONFIG_SUCCESS';
export const QUERY_CHANNEL_CONFIG_ERROR = 'QUERY_CHANNEL_CONFIG_ERROR';
export const QUERY_CHANNEL_CONFIG_IN_PROGRESS = 'QUERY_CHANNEL_CONFIG_IN_PROGRESS';

export const QUERY_CHANNEL_AUTH_DETAILS_IN_PROGRESS = 'QUERY_CHANNEL_AUTH_DETAILS_IN_PROGRESS';
export const QUERY_CHANNEL_AUTH_DETAILS_SUCCESS = 'QUERY_CHANNEL_AUTH_DETAILS_SUCCESS';
export const QUERY_CHANNEL_AUTH_DETAILS_ERROR = 'QUERY_CHANNEL_AUTH_DETAILS_ERROR';

export const QUERY_CONTRACT_PARAMS_IN_PROGRESS = 'QUERY_CONTRACT_PARAMS_IN_PROGRESS';
export const QUERY_CONTRACT_PARAMS_SUCCESS = 'QUERY_CONTRACT_PARAMS_SUCCESS';
export const QUERY_CONTRACT_PARAMS_ERROR = 'QUERY_CONTRACT_PARAMS_ERROR';
