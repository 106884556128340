import { combineReducers } from 'redux';
import { DEFAULT_LIMIT, DEFAULT_ORDER, DEFAULT_SKIP, DEFAULT_SORT_BY, DEFAULT_TOTAL } from '../../config';
import {
    DOWNLOAD_IV_STATS_ERROR,
    DOWNLOAD_IV_STATS_IN_PROGRESS,
    DOWNLOAD_IV_STATS_SUCCESS,
    FETCH_LEADERBOARD_ERROR,
    FETCH_LEADERBOARD_IN_PROGRESS,
    FETCH_LEADERBOARD_SUCCESS,
} from '../../constants/interactiveVideos/stats';

const leaderboard = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: DEFAULT_TOTAL,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
    search: '',
}, action) => {
    switch (action.type) {
    case FETCH_LEADERBOARD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_LEADERBOARD_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
            sortBy: action.sortBy,
            order: action.order,
            search: action.search,
        };
    }
    case FETCH_LEADERBOARD_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

const downloadIVStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DOWNLOAD_IV_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case DOWNLOAD_IV_STATS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case DOWNLOAD_IV_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };

    default:
        return state;
    }
};

export default combineReducers({
    leaderboard,
    downloadIVStats,
});
