import { combineReducers } from 'redux';
import {
    ACTIVE_STEP_SET,
    ASSET_ADD_ERROR,
    ASSET_ADD_IN_PROGRESS,
    ASSET_ADD_SUCCESS,
    ASSET_CARDS_FETCH_ERROR,
    ASSET_CARDS_FETCH_IN_PROGRESS,
    ASSET_CARDS_FETCH_SUCCESS,
    ASSET_DELETE_ERROR,
    ASSET_DELETE_IN_PROGRESS,
    ASSET_DELETE_SUCCESS,
    ASSET_FETCH_ERROR,
    ASSET_FETCH_IN_PROGRESS,
    ASSET_FETCH_SUCCESS,
    ASSET_UPDATE_ERROR,
    ASSET_UPDATE_IN_PROGRESS,
    ASSET_UPDATE_SUCCESS,
    AVATAR_UPLOAD_ERROR,
    AVATAR_UPLOAD_IN_PROGRESS,
    AVATAR_UPLOAD_SUCCESS,
    BULK_MINT_SET,
    CREATE_COLLECTION_ACTIVE_STEP_SET,
    CREATE_COLLECTION_DESCRIPTION_SET,
    CREATE_COLLECTION_DIALOG_HIDE,
    CREATE_COLLECTION_DIALOG_SHOW,
    CREATE_COLLECTION_IMAGE_URL_SET,
    CREATE_COLLECTION_JSON_SCHEMA_SET,
    CREATE_COLLECTION_NAME_SET,
    CREATE_COLLECTION_SYMBOL_SET,
    DATE_DIALOG_HIDE,
    DATE_DIALOG_SHOW,
    EMPTY_VALUES_SET,
    MEDIA_URL_SET,
    MINT_COUNT_SET,
    MINT_COUNT_TAB_SET,
    MULTIPLE_MINT_TAB_SET,
    PLAYER_SET,
    PREVIEW_DIALOG_HIDE,
    PREVIEW_DIALOG_SHOW,
    PREVIEW_URL_SET,
    SCHEMA_FETCH_ERROR,
    SCHEMA_FETCH_IN_PROGRESS,
    SCHEMA_FETCH_SUCCESS,
    SCHEMA_SET,
    SUFFIX_COUNT_SET,
    SUFFIX_VALUE_SET,
} from '../../constants/createAssets';
import _new from './new';
import mintNft from './mintNft';
import listing from './listing';
import { DISCONNECT_SET, TX_HASH_IN_PROGRESS_FALSE_SET } from '../../constants/account';
import { UPDATE_COLLECTION_SET } from '../../constants/collection';
import { COLLECTION_CONFIRM_DIALOG_HIDE } from '../../constants/createAssets/mintNft';
import { suffixOptions } from '../../utils/defaultOptions';
import { getAbsoluteHash } from '../../utils/ipfs';

const activeStep = (state = 0, action) => {
    if (action.type === ACTIVE_STEP_SET) {
        return action.step;
    } else if (action.type === EMPTY_VALUES_SET) {
        return 0;
    }

    return state;
};

const player = (state = null, action) => {
    if (action.type === PLAYER_SET) {
        return action.value;
    }

    return state;
};

const previewDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case PREVIEW_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case PREVIEW_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const createCollection = (state = {
    dialog: false,
    value: '',
    symbol: '',
    description: '',
    descriptionValid: true,
    jsonSchema: '',
    jsonValid: true,
    imageUrl: '',
    activeStep: 0,
}, action) => {
    switch (action.type) {
    case CREATE_COLLECTION_DIALOG_SHOW:
        return {
            ...state,
            dialog: true,
            value: action.value || '',
            symbol: '',
            jsonSchema: '',
            jsonValid: true,
        };
    case CREATE_COLLECTION_DIALOG_HIDE:
        return {
            ...state,
            activeStep: 0,
            dialog: false,
            jsonSchema: '',
            jsonValid: true,
        };
    case CREATE_COLLECTION_NAME_SET:
        return {
            ...state,
            value: action.value,
        };
    case CREATE_COLLECTION_SYMBOL_SET:
        return {
            ...state,
            symbol: action.value,
        };
    case CREATE_COLLECTION_DESCRIPTION_SET:
        return {
            ...state,
            description: action.value,
            descriptionValid: action.valid,
        };
    case CREATE_COLLECTION_JSON_SCHEMA_SET:
        return {
            ...state,
            jsonSchema: action.value,
            jsonValid: action.valid,
        };
    case CREATE_COLLECTION_IMAGE_URL_SET:
        return {
            ...state,
            imageUrl: action.value,
        };
    case TX_HASH_IN_PROGRESS_FALSE_SET:
        return {
            ...state,
            imageUrl: '',
        };
    case CREATE_COLLECTION_ACTIVE_STEP_SET:
        return {
            ...state,
            activeStep: action.step,
        };
    case UPDATE_COLLECTION_SET:
        return {
            ...state,
            value: action.value && action.value.name ? action.value.name : '',
            symbol: action.value && action.value.symbol ? action.value.symbol : '',
            description: action.value && action.value.description ? action.value.description : '',
            imageUrl: action.value && action.value.preview_uri ? action.value.preview_uri : '',
            jsonSchema: action.value && action.value.schema ? action.value.schema : '',
        };

    default:
        return state;
    }
};

const updateCollection = (state = {
    value: {},
}, action) => {
    if (action.type === UPDATE_COLLECTION_SET) {
        return {
            value: action.value,
        };
    }

    return state;
};

const asset = (state = {
    inProgress: false,
    value: {},
    deleteInProgress: false,
}, action) => {
    switch (action.type) {
    case ASSET_ADD_IN_PROGRESS:
    case ASSET_FETCH_IN_PROGRESS:
    case ASSET_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_ADD_SUCCESS:
    case ASSET_FETCH_SUCCESS:
    case ASSET_UPDATE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case ASSET_ADD_ERROR:
    case ASSET_FETCH_ERROR:
    case ASSET_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case ASSET_DELETE_IN_PROGRESS:
        return {
            ...state,
            deleteInProgress: true,
        };
    case ASSET_DELETE_ERROR:
    case ASSET_DELETE_SUCCESS:
        return {
            ...state,
            deleteInProgress: false,
        };
    case EMPTY_VALUES_SET:
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const cards = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ASSET_CARDS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ASSET_CARDS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ASSET_CARDS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const dateDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case DATE_DIALOG_SHOW:
        return {
            open: true,
        };
    case DATE_DIALOG_HIDE:
        return {
            open: false,
        };
    default:
        return state;
    }
};

const schemas = (state = {
    inProgress: false,
    list: [],
    value: null,
}, action) => {
    switch (action.type) {
    case SCHEMA_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SCHEMA_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            list: action.value,
        };
    case SCHEMA_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case SCHEMA_SET:
        return {
            ...state,
            value: action.value,
        };
    case COLLECTION_CONFIRM_DIALOG_HIDE:
    case CREATE_COLLECTION_DIALOG_HIDE:
    case CREATE_COLLECTION_DIALOG_SHOW:
        return {
            ...state,
            value: null,
        };
    default:
        return state;
    }
};

const avatar = (state = {
    inProgress: false,
    value: null,
}, action) => {
    switch (action.type) {
    case AVATAR_UPLOAD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case AVATAR_UPLOAD_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case AVATAR_UPLOAD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const bulkMint = (state = {
    show: false,
}, action) => {
    switch (action.type) {
    case BULK_MINT_SET:
        return {
            show: action.show,
        };
    case EMPTY_VALUES_SET:
        return {
            show: false,
        };
    default:
        return state;
    }
};

const mintCount = (state = {
    tab: 'single',
    count: null,
}, action) => {
    switch (action.type) {
    case MINT_COUNT_TAB_SET:
        return {
            ...state,
            tab: action.value,
        };
    case MINT_COUNT_SET:
        return {
            ...state,
            count: action.value,
        };
    case EMPTY_VALUES_SET:
        return {
            tab: 'single',
            count: null,
        };
    default:
        return state;
    }
};

const multipleMint = (state = {
    tab: 'disable',
}, action) => {
    switch (action.type) {
    case MULTIPLE_MINT_TAB_SET:
        return {
            ...state,
            tab: action.value,
        };
    case EMPTY_VALUES_SET:
        return {
            tab: 'disable',
        };
    default:
        return state;
    }
};

const suffix = (state = {
    value: {
        name: 'No Suffix',
        value: null,
    },
    options: suffixOptions,
    count: null,
    countValid: true,
}, action) => {
    switch (action.type) {
    case SUFFIX_VALUE_SET:
        return {
            ...state,
            value: action.value,
        };
    case SUFFIX_COUNT_SET:
        return {
            ...state,
            count: action.value,
            countValid: action.valid,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            value: {
                name: 'No Suffix',
                value: null,
            },
            count: null,
        };
    default:
        return state;
    }
};

const mediaURL = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case MEDIA_URL_SET:
        return {
            ...state,
            value: action.value,
        };
    case ASSET_FETCH_SUCCESS: {
        return {
            ...state,
            value: action.value && action.value.file && action.value.file['IPFS_hash']
                ? getAbsoluteHash(action.value.file['IPFS_hash']) : '',
        };
    }
    case EMPTY_VALUES_SET:
        return {
            value: '',
        };
    default:
        return state;
    }
};

const previewURL = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case PREVIEW_URL_SET:
        return {
            ...state,
            value: action.value,
        };
    case ASSET_FETCH_SUCCESS: {
        return {
            ...state,
            value: action.value && action.value.file && action.value.file['preview_IPFS_hash']
                ? getAbsoluteHash(action.value.file['preview_IPFS_hash']) : '',
        };
    }
    // case CREATE_COLLECTION_IMAGE_URL_SET: {
    //     return {
    //         ...state,
    //         value: action.value,
    //     };
    // }
    case EMPTY_VALUES_SET:
        return {
            value: '',
        };

    default:
        return state;
    }
};

export default combineReducers({
    activeStep,
    new: _new,
    player,
    previewDialog,
    createCollection,
    updateCollection,
    asset,
    nft: mintNft,
    cards,
    dateDialog,
    listing,
    schemas,
    avatar,
    bulkMint,
    mintCount,
    multipleMint,
    suffix,
    mediaURL,
    previewURL,
});
