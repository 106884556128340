export const urlConnectMediaNode = (URL) => `${URL}/api/user/connect-bc-account`;
export const urlVerifyMediaNode = (URL, userId) => `${URL}/api/user/${userId}/verify-bc-account`;
export const urlFetchProfileDetails = (URL) => `${URL}/api/user/profile/details`;
export const urlFetchMediaNodeStatus = (URL) => `${URL}/api/status`;
export const urlFetchMediaNodeAccessToken = (URL) => `${URL}/api/user/auth/refresh-token`;
export const urlFetchLiveStreams = (URL) => `${URL}/api/live-streams`;
export const urlFetchLiveStreamDetails = (URL, ID) => `${URL}/api/live-streams/${ID}/config`;
export const urlFetchLiveStreamStatus = (URL, ID) => `${URL}/api/live-streams/${ID}/status`;
export const urlFetchLiveStreamWatchUrl = (URL, ID) => `${URL}/api/live-streams/${ID}/watch-url?ip=${URL}`;
export const urlstreamStart = (URL, ID) => `${URL}/api/live-streams/${ID}/start`;
export const urlstreamStop = (URL, ID) => `${URL}/api/live-streams/${ID}/stop`;
export const urlupdateLiveStreamDestination = (URL, ID) => `${URL}/api/live-streams/${ID}`;
export const urladdStreamText = (URL, ID) => `${URL}/api/live-streams/${ID}/live-text`;
