export const LIST_NFT_DIALOG_SHOW = 'LIST_NFT_DIALOG_SHOW';
export const LIST_NFT_DIALOG_HIDE = 'LIST_NFT_DIALOG_HIDE';

export const CONFIRM_LIST_NFT_DIALOG_SHOW = 'CONFIRM_LIST_NFT_DIALOG_SHOW';
export const CONFIRM_LIST_NFT_DIALOG_HIDE = 'CONFIRM_LIST_NFT_DIALOG_HIDE';

export const LIST_NFT_SUCCESS_DIALOG_SHOW = 'LIST_NFT_SUCCESS_DIALOG_SHOW';
export const LIST_NFT_SUCCESS_DIALOG_HIDE = 'LIST_NFT_SUCCESS_DIALOG_HIDE';

export const DE_LISTED_DIALOG_SHOW = 'DE_LISTED_DIALOG_SHOW';
export const DE_LISTED_DIALOG_HIDE = 'DE_LISTED_DIALOG_HIDE';

export const LISTINGS_FETCH_IN_PROGRESS = 'LISTINGS_FETCH_IN_PROGRESS';
export const LISTINGS_FETCH_SUCCESS = 'LISTINGS_FETCH_SUCCESS';
export const LISTINGS_FETCH_ERROR = 'LISTINGS_FETCH_ERROR';

export const IBC_TOKENS_LIST_FETCH_IN_PROGRESS = 'IBC_TOKENS_LIST_FETCH_IN_PROGRESS';
export const IBC_TOKENS_LIST_FETCH_SUCCESS = 'IBC_TOKENS_LIST_FETCH_SUCCESS';
export const IBC_TOKENS_LIST_FETCH_ERROR = 'IBC_TOKENS_LIST_FETCH_ERROR';

export const SPLIT_INFO_SET = 'SPLIT_INFO_SET';
export const SPLIT_INFO_STATUS_SET = 'SPLIT_INFO_STATUS_SET';
export const WHITELIST_SWITCH_SET = 'WHITELIST_SWITCH_SET';
export const WHITELIST_VALUE_SET = 'WHITELIST_VALUE_SET';
export const START_DATE_SET = 'START_DATE_SET';
export const END_DATE_SET = 'END_DATE_SET';
export const LIST_TYPE_SET = 'LIST_TYPE_SET';

export const BID_PERCENTAGE_SET = 'BID_PERCENTAGE_SET';
