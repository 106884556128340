export const IV_CHANNEL_TAB_VALUE_SET = 'IV_CHANNEL_TAB_VALUE_SET';

export const INTERACTIVE_TABS_VALUE_SET = 'INTERACTIVE_TABS_VALUE_SET';
export const INTERACTIVE_SEARCH_VALUE_SET = 'INTERACTIVE_SEARCH_VALUE_SET';

export const FETCH_IV_STATS_SUCCESS = 'FETCH_IV_STATS_SUCCESS';
export const FETCH_IV_STATS_IN_PROGRESS = 'FETCH_IV_STATS_IN_PROGRESS';
export const FETCH_IV_STATS_ERROR = 'FETCH_IV_STATS_ERROR';

export const INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const INTERACTIVE_VIDEOS_FETCH_ERROR = 'INTERACTIVE_VIDEOS_FETCH_ERROR';

export const VOD_INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'VOD_INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const VOD_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'VOD_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const VOD_INTERACTIVE_VIDEOS_FETCH_ERROR = 'VOD_INTERACTIVE_VIDEOS_FETCH_ERROR';

export const ACTIVE_INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'ACTIVE_INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const ACTIVE_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'ACTIVE_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const ACTIVE_INTERACTIVE_VIDEOS_FETCH_ERROR = 'ACTIVE_INTERACTIVE_VIDEOS_FETCH_ERROR';

export const UPCOMING_INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'UPCOMING_INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const UPCOMING_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'UPCOMING_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const UPCOMING_INTERACTIVE_VIDEOS_FETCH_ERROR = 'UPCOMING_INTERACTIVE_VIDEOS_FETCH_ERROR';

export const MY_INTERACTIVE_VIDEOS_FETCH_SUCCESS = 'MY_INTERACTIVE_VIDEOS_FETCH_SUCCESS';
export const MY_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS = 'MY_INTERACTIVE_VIDEOS_FETCH_IN_PROGRESS';
export const MY_INTERACTIVE_VIDEOS_FETCH_ERROR = 'MY_INTERACTIVE_VIDEOS_FETCH_ERROR';

export const FETCH_PUBLISHED_VIDEOS_IN_PROGRESS = 'FETCH_PUBLISHED_VIDEOS_IN_PROGRESS';
export const FETCH_PUBLISHED_VIDEOS_SUCCESS = 'FETCH_PUBLISHED_VIDEOS_SUCCESS';
export const FETCH_PUBLISHED_VIDEOS_ERROR = 'FETCH_PUBLISHED_VIDEOS_ERROR';

export const FETCH_GATED_INTERACTIONS_IN_PROGRESS = 'FETCH_GATED_INTERACTIONS_IN_PROGRESS';
export const FETCH_GATED_INTERACTIONS_SUCCESS = 'FETCH_GATED_INTERACTIONS_SUCCESS';
export const FETCH_GATED_INTERACTIONS_ERROR = 'FETCH_GATED_INTERACTIONS_ERROR';

export const FETCH_VIDEO_MINTS_IN_PROGRESS = 'FETCH_VIDEO_MINTS_IN_PROGRESS';
export const FETCH_VIDEO_MINTS_SUCCESS = 'FETCH_VIDEO_MINTS_SUCCESS';
export const FETCH_VIDEO_MINTS_ERROR = 'FETCH_VIDEO_MINTS_ERROR';

export const PARTICIPATION_NFTS_LIST_IN_PROGRESS = 'PARTICIPATION_NFTS_LIST_IN_PROGRESS';
export const PARTICIPATION_NFTS_LIST_SUCCESS = 'PARTICIPATION_NFTS_LIST_SUCCESS';
export const PARTICIPATION_NFTS_LIST_ERROR = 'PARTICIPATION_NFTS_LIST_ERROR';

export const PUBLISH_VISIBILITY_TAB_SET = 'PUBLISH_VISIBILITY_TAB_SET';
export const PUBLISH_SCHEDULE_TAB_SET = 'PUBLISH_SCHEDULE_TAB_SET';
export const PUBLISH_SCHEDULE_START_TIME_SET = 'PUBLISH_SCHEDULE_START_TIME_SET';

export const PUBLISH_WHITELIST_SET = 'PUBLISH_WHITELIST_SET';
export const PUBLISH_WHITELIST_UNSET = 'PUBLISH_WHITELIST_UNSET';
export const PUBLISH_ELIGIBLE_COLLECTIONS_SET = 'PUBLISH_ELIGIBLE_COLLECTIONS_SET';
export const PUBLISH_BLOCK_LIST_ACCOUNTS_SET = 'PUBLISH_BLOCK_LIST_ACCOUNTS_SET';
export const TOKEN_PROTECTED_SWITCH_SET = 'TOKEN_PROTECTED_SWITCH_SET';
export const TOKEN_PROTECTED_TYPE_SWITCH_SET = 'TOKEN_PROTECTED_TYPE_SWITCH_SET';
export const SELECTED_COLLECTION_SET = 'SELECTED_COLLECTION_SET';
export const GATED_UNTIL_VALUE_SET = 'GATED_UNTIL_VALUE_SET';

export const EDIT_INTERACTION_CARD_SHOW = 'EDIT_INTERACTION_CARD_SHOW';
export const EDIT_INTERACTION_CARD_HIDE = 'EDIT_INTERACTION_CARD_HIDE';
export const ADD_INTERACTION_SET = 'ADD_INTERACTION_SET';

// asset info
export const ASSET_INFO_VIDEO_TITLE_SET = 'ASSET_INFO_VIDEO_TITLE_SET';
export const ASSET_INFO_VIDEO_DESCRIPTION_SET = 'ASSET_INFO_VIDEO_DESCRIPTION_SET';
export const ASSET_INFO_VIDEO_SOURCE_FILE_SET = 'ASSET_INFO_VIDEO_SOURCE_FILE_SET';

export const PUBLISH_DIALOG_SHOW = 'PUBLISH_DIALOG_SHOW';
export const PUBLISH_DIALOG_HIDE = 'PUBLISH_DIALOG_HIDE';

export const FETCH_NETWORKS_IN_PROGRESS = 'FETCH_NETWORKS_IN_PROGRESS';
export const FETCH_NETWORKS_SUCCESS = 'FETCH_NETWORKS_SUCCESS';
export const FETCH_NETWORKS_ERROR = 'FETCH_NETWORKS_ERROR';

export const FETCH_NETWORK_DETAILS_IN_PROGRESS = 'FETCH_NETWORK_DETAILS_IN_PROGRESS';
export const FETCH_NETWORK_DETAILS_SUCCESS = 'FETCH_NETWORK_DETAILS_SUCCESS';
export const FETCH_NETWORK_DETAILS_ERROR = 'FETCH_NETWORK_DETAILS_ERROR';

export const SELECTED_NETWORK_SET = 'SELECTED_NETWORK_SET';

export const FETCH_PROPOSALS_IN_PROGRESS = 'FETCH_PROPOSALS_IN_PROGRESS';
export const FETCH_PROPOSALS_SUCCESS = 'FETCH_PROPOSALS_SUCCESS';
export const FETCH_PROPOSALS_ERROR = 'FETCH_PROPOSALS_ERROR';

export const FETCH_PROPOSAL_DETAILS_IN_PROGRESS = 'FETCH_PROPOSAL_DETAILS_IN_PROGRESS';
export const FETCH_PROPOSAL_DETAILS_SUCCESS = 'FETCH_PROPOSAL_DETAILS_SUCCESS';
export const FETCH_PROPOSAL_DETAILS_ERROR = 'FETCH_PROPOSAL_DETAILS_ERROR';

export const SELECTED_PROPOSAL_SET = 'SELECTED_PROPOSAL_SET';
