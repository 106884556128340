export const NEW_INTERACTIVE_TAB_SET = 'NEW_INTERACTIVE_TAB_SET';
export const INTERACTIONS_LIST_SET = 'INTERACTIONS_LIST_SET';

export const INTERACTIVE_TIME_SET = 'INTERACTIVE_TIME_SET';
export const INTERACTIVE_TYPE_SET = 'INTERACTIVE_TYPE_SET';
export const INTERACTIVE_TYPE_DURATION_SET = 'INTERACTIVE_TYPE_DURATION_SET';
export const INTERACTIVE_QUESTION_SET = 'INTERACTIVE_QUESTION_SET';
export const INTERACTIVE_PLACEHOLDER_SET = 'INTERACTIVE_PLACEHOLDER_SET';
export const INTERACTIVE_ANSWER_SET = 'INTERACTIVE_ANSWER_SET';
export const INTERACTIVE_REQUIRED_SET = 'INTERACTIVE_REQUIRED_SET';

export const INTERACTIVE_OPTIONS_SET = 'INTERACTIVE_OPTIONS_SET';
export const INTERACTIVE_OPTION_TEXT_SET = 'INTERACTIVE_OPTION_TEXT_SET';
export const INTERACTIVE_OPTIONS_LIST_SET = 'INTERACTIVE_OPTIONS_LIST_SET';
export const INTERACTIVE_SEEK_TO_SET = 'INTERACTIVE_SEEK_TO_SET';
export const INTERACTIVE_TIMESTAMP_SET = 'INTERACTIVE_TIMESTAMP_SET';
export const INTERACTIVE_NETWORKS_SET = 'INTERACTIVE_NETWORKS_SET';
export const INTERACTIVE_ADDRESS_SET = 'INTERACTIVE_ADDRESS_SET';
export const INTERACTIVE_PROPOSAL_NUMBER_SET = 'INTERACTIVE_PROPOSAL_NUMBER_SET';

export const INTERACTIVE_STATS_SEARCH_SET = 'INTERACTIVE_STATS_SEARCH_SET';
export const INTERACTIVE_STATS_SORT_VALUE_SET = 'INTERACTIVE_STATS_SORT_VALUE_SET';
export const INTERACTIVE_STATS_EXPORT_ACTIVITY_SET = 'INTERACTIVE_STATS_EXPORT_ACTIVITY_SET';

export const INTERACTION_MARK_SET = 'INTERACTION_MARK_SET';

export const INTERACTIVE_VIDEO_ADD_IN_PROGRESS = 'INTERACTIVE_VIDEO_ADD_IN_PROGRESS';
export const INTERACTIVE_VIDEO_ADD_SUCCESS = 'INTERACTIVE_VIDEO_ADD_SUCCESS';
export const INTERACTIVE_VIDEO_ADD_ERROR = 'INTERACTIVE_VIDEO_ADD_ERROR';

export const INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS = 'INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS';
export const INTERACTIVE_VIDEO_UPDATE_SUCCESS = 'INTERACTIVE_VIDEO_UPDATE_SUCCESS';
export const INTERACTIVE_VIDEO_UPDATE_ERROR = 'INTERACTIVE_VIDEO_UPDATE_ERROR';

export const INTERACTIVE_VIDEO_DELETE_IN_PROGRESS = 'INTERACTIVE_VIDEO_DELETE_IN_PROGRESS';
export const INTERACTIVE_VIDEO_DELETE_SUCCESS = 'INTERACTIVE_VIDEO_DELETE_SUCCESS';
export const INTERACTIVE_VIDEO_DELETE_ERROR = 'INTERACTIVE_VIDEO_DELETE_ERROR';

export const INTEGRATION_ADD_IN_PROGRESS = 'INTEGRATION_ADD_IN_PROGRESS';
export const INTEGRATION_ADD_SUCCESS = 'INTEGRATION_ADD_SUCCESS';
export const INTEGRATION_ADD_ERROR = 'INTEGRATION_ADD_ERROR';

export const INTEGRATION_DELETE_IN_PROGRESS = 'INTEGRATION_DELETE_IN_PROGRESS';
export const INTEGRATION_DELETE_SUCCESS = 'INTEGRATION_DELETE_SUCCESS';
export const INTEGRATION_DELETE_ERROR = 'INTEGRATION_DELETE_ERROR';

export const INTEGRATION_UPDATE_IN_PROGRESS = 'INTEGRATION_UPDATE_IN_PROGRESS';
export const INTEGRATION_UPDATE_SUCCESS = 'INTEGRATION_UPDATE_SUCCESS';
export const INTEGRATION_UPDATE_ERROR = 'INTEGRATION_UPDATE_ERROR';

export const INTERACTIVE_VIDEO_FETCH_IN_PROGRESS = 'INTERACTIVE_VIDEO_FETCH_IN_PROGRESS';
export const INTERACTIVE_VIDEO_FETCH_SUCCESS = 'INTERACTIVE_VIDEO_FETCH_SUCCESS';
export const INTERACTIVE_VIDEO_FETCH_ERROR = 'INTERACTIVE_VIDEO_FETCH_ERROR';

export const INTERACTIVE_VIDEO_PUBLISH_IN_PROGRESS = 'INTERACTIVE_VIDEO_PUBLISH_IN_PROGRESS';
export const INTERACTIVE_VIDEO_PUBLISH_SUCCESS = 'INTERACTIVE_VIDEO_PUBLISH_SUCCESS';
export const INTERACTIVE_VIDEO_PUBLISH_ERROR = 'INTERACTIVE_VIDEO_PUBLISH_ERROR';

export const INTERACTIVE_VIDEO_MINT_ENABLE_IN_PROGRESS = 'INTERACTIVE_VIDEO_MINT_ENABLE_IN_PROGRESS';
export const INTERACTIVE_VIDEO_MINT_ENABLE_SUCCESS = 'INTERACTIVE_VIDEO_MINT_ENABLE_SUCCESS';
export const INTERACTIVE_VIDEO_MINT_ENABLE_ERROR = 'INTERACTIVE_VIDEO_MINT_ENABLE_ERROR';

export const INTERACTIVE_VIDEO_PUBLISH_DISABLE_IN_PROGRESS = 'INTERACTIVE_VIDEO_PUBLISH_DISABLE_IN_PROGRESS';
export const INTERACTIVE_VIDEO_PUBLISH_DISABLE_SUCCESS = 'INTERACTIVE_VIDEO_PUBLISH_DISABLE_SUCCESS';
export const INTERACTIVE_VIDEO_PUBLISH_DISABLE_ERROR = 'INTERACTIVE_VIDEO_PUBLISH_DISABLE_ERROR';

export const INTERACTIVE_VIDEO_PUBLISH_ENABLE_IN_PROGRESS = 'INTERACTIVE_VIDEO_PUBLISH_ENABLE_IN_PROGRESS';
export const INTERACTIVE_VIDEO_PUBLISH_ENABLE_SUCCESS = 'INTERACTIVE_VIDEO_PUBLISH_ENABLE_SUCCESS';
export const INTERACTIVE_VIDEO_PUBLISH_ENABLE_ERROR = 'INTERACTIVE_VIDEO_PUBLISH_ENABLE_ERROR';

// iv delete dialog
export const IV_DELETE_DIALOG_SHOW = 'IV_DELETE_DIALOG_SHOW';
export const IV_DELETE_DIALOG_HIDE = 'IV_DELETE_DIALOG_HIDE';

// unpublish dialog
export const UNPUBLISH_DIALOG_SHOW = 'UNPUBLISH_DIALOG_SHOW';
export const UNPUBLISH_DILOG_HIDE = 'UNPUBLISH_DILOG_HIDE';

export const IMAGE_URL_SET = 'IMAGE_URL_SET';
export const VIDEO_MINT_SETUP_FROM_PUBLISH_PAGE_SET = 'VIDEO_MINT_SETUP_FROM_PUBLISH_PAGE_SET';
