import { combineReducers } from 'redux';
import { AUTHZ_ADDRESS_SET } from '../constants/authorization';

const authzAddress = (state = '', action) => {
    switch (action.type) {
    case AUTHZ_ADDRESS_SET:
        return action.value;
    default:
        return state;
    }
};

export default combineReducers({
    authzAddress,
});
