import { combineReducers } from 'redux';
import {
    ADD_DESTINATION_SET, ADD_STREAM_KEY_SET, ADD_STREAM_URL_SET, INPUT_STREAM_KEY_SET, INPUT_STREAM_URL_SET, OUTPUT_DESTINATION_DIALOG_HIDE, OUTPUT_DESTINATION_DIALOG_SHOW,
    LIVE_STREAM_TAB_SET,
    LIVE_STREAMS_DETAILS_FETCH_ERROR,
    LIVE_STREAMS_DETAILS_FETCH_IN_PROGRESS,
    LIVE_STREAMS_DETAILS_FETCH_SUCCESS,
    LIVE_STREAMS_DETAILS_SET,
    LIVE_STREAMS_FETCH_ERROR,
    LIVE_STREAMS_FETCH_IN_PROGRESS,
    LIVE_STREAMS_FETCH_SUCCESS,
    LIVE_STREAMS_STATUS_FETCH_ERROR,
    LIVE_STREAMS_STATUS_FETCH_IN_PROGRESS,
    LIVE_STREAMS_STATUS_FETCH_SUCCESS,
    LIVE_STREAMS_WATCH_URL_FETCH_ERROR,
    LIVE_STREAMS_WATCH_URL_FETCH_IN_PROGRESS,
    LIVE_STREAMS_WATCH_URL_FETCH_SUCCESS,
    STREAM_START_ERROR,
    STREAM_START_IN_PROGRESS,
    STREAM_START_SUCCESS,
    STREAM_STOP_ERROR,
    STREAM_STOP_IN_PROGRESS,
    STREAM_STOP_SUCCESS,
    LIVE_STREAM_DESTINATION_UPDATE_IN_PROGRESS,
    LIVE_STREAM_DESTINATION_UPDATE_SUCCESS,
    LIVE_STREAM_DESTINATION_UPDATE_ERROR,
    LIVE_STREAM_TEXT_ADD_IN_PROGRESS,
    LIVE_STREAM_TEXT_ADD_SUCCESS,
    LIVE_STREAM_TEXT_ADD_ERROR,
    LIVE_STREAM_TEXT_SET,
    LIVE_STREAM_TEXT_ENABLE_SET,
    OUTPUT_DESTINATIONS_LIST_SET,
} from '../../constants/mediaNodes/liveStream';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';

const tabValue = (state = 'stream', action) => {
    switch (action.type) {
    case LIVE_STREAM_TAB_SET:
        return action.value;
    default:
        return state;
    }
};

const liveStreams = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case LIVE_STREAMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LIVE_STREAMS_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case LIVE_STREAMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const streamDetails = (state = {
    value: {},
    status: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case LIVE_STREAMS_DETAILS_FETCH_IN_PROGRESS:
    case STREAM_START_IN_PROGRESS:
    case STREAM_STOP_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LIVE_STREAMS_STATUS_FETCH_IN_PROGRESS: {
        return {
            ...state,
            inProgress: true,
            status: action.reset ? {} : state.status,
        };
    }
    case LIVE_STREAMS_DETAILS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case LIVE_STREAMS_STATUS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            status: action.value,
        };
    case LIVE_STREAMS_DETAILS_FETCH_ERROR:
    case LIVE_STREAMS_STATUS_FETCH_ERROR:
    case STREAM_START_ERROR:
    case STREAM_STOP_ERROR:
    case STREAM_START_SUCCESS:
    case STREAM_STOP_SUCCESS:
        return {
            ...state,
            inProgress: false,
        };
    case LIVE_STREAMS_DETAILS_SET:
        return {
            ...state,
            value: action.value,
        };
    default:
        return state;
    }
};

const watchUrl = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case LIVE_STREAMS_WATCH_URL_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LIVE_STREAMS_WATCH_URL_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case LIVE_STREAMS_WATCH_URL_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const inputStreamUrl = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case INPUT_STREAM_URL_SET:
        return {
            ...state,
            value: action.value,
        };
    case LIVE_STREAMS_DETAILS_SET:
        return {
            ...state,
            value: action.value && action.value.configuration && action.value.configuration.live_feed_config && action.value.configuration.live_feed_config.stream_url,
        };

    default:
        return state;
    }
};

const inputStreamKey = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case INPUT_STREAM_KEY_SET:
        return {
            ...state,
            value: action.value,
        };
    case LIVE_STREAMS_DETAILS_SET:
        return {
            ...state,
            value: action.value && action.value.configuration && action.value.configuration.live_feed_config && action.value.configuration.live_feed_config.stream_key,
        };

    default:
        return state;
    }
};

const outputDestinationList = (state = {
    value: [{
        destination: {
            name: 'YouTube',
            value: 'youtube',
        },
        url: '',
        key: '',
    }],
}, action) => {
    switch (action.type) {
    case OUTPUT_DESTINATIONS_LIST_SET:
        return {
            ...state,
            value: action.value,
        };
    case LIVE_STREAMS_DETAILS_SET:
    case LIVE_STREAMS_DETAILS_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value.configuration && action.value.configuration.stream_destinations && action.value.configuration.stream_destinations.length
                ? action.value.configuration.stream_destinations : state.value,
        };
    default:
        return state;
    }
};

const addDestination = (state = {
    value: {},
}, action) => {
    switch (action.type) {
    case ADD_DESTINATION_SET:
        return {
            ...state,
            value: action.value,
        };

    default:
        return state;
    }
};

const addStreamUrl = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case ADD_STREAM_URL_SET:
        return {
            ...state,
            value: action.value,
        };

    default:
        return state;
    }
};

const addStreamKey = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case ADD_STREAM_KEY_SET:
        return {
            ...state,
            value: action.value,
        };

    default:
        return state;
    }
};

const outputDestinationDialog = (state = {
    value: {},
    open: false,
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case OUTPUT_DESTINATION_DIALOG_SHOW:
        return {
            ...state,
            open: true,
            value: action.value,
        };
    case OUTPUT_DESTINATION_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            value: {},
        };
    case LIVE_STREAM_DESTINATION_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LIVE_STREAM_DESTINATION_UPDATE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    case LIVE_STREAM_DESTINATION_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const streamText = (state = {
    value: '',
    enable: false,
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case LIVE_STREAM_TEXT_ADD_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LIVE_STREAM_TEXT_ADD_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    case LIVE_STREAM_TEXT_ADD_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case LIVE_STREAM_TEXT_SET:
        return {
            ...state,
            value: action.value,
        };
    case LIVE_STREAM_TEXT_ENABLE_SET:
        return {
            ...state,
            enable: action.value,
        };
    case LIVE_STREAMS_DETAILS_SET:
    case LIVE_STREAMS_DETAILS_FETCH_SUCCESS:
        return {
            ...state,
            value: action.value && action.value.configuration &&
                action.value.configuration.stream_live_text && action.value.configuration.stream_live_text,
            enable: action.value && action.value.configuration &&
                action.value.configuration.in_stream_config && action.value.configuration.in_stream_config.text_scroll_enabled,
        };
    default:
        return state;
    }
};

export default combineReducers({
    tabValue,
    liveStreams,
    streamDetails,
    watchUrl,

    inputStreamUrl,
    inputStreamKey,
    outputDestinationList,
    addDestination,
    addStreamUrl,
    addStreamKey,
    outputDestinationDialog,
    streamText,
});
