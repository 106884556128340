export const VIDEO_SETUP_SELECT_VIDEOS_DIALOG_SHOW = 'VIDEO_SETUP_SELECT_VIDEOS_DIALOG_SHOW';
export const VIDEO_SETUP_SELECT_VIDEOS_DIALOG_HIDE = 'VIDEO_SETUP_SELECT_VIDEOS_DIALOG_HIDE';
export const VIDEO_MINT_SELECT_VIDEO_SET = 'VIDEO_MINT_SELECT_VIDEO_SET';

export const VIDEO_SETUP_SUCCESS_DIALOG_SHOW = 'VIDEO_SETUP_SUCCESS_DIALOG_SHOW';
export const VIDEO_SETUP_SUCCESS_DIALOG_HIDE = 'VIDEO_SETUP_SUCCESS_DIALOG_HIDE';

export const VIDEO_MINT_ENABLE_SET = 'VIDEO_MINT_ENABLE_SET';
export const DROPS_COLLECTION_NAME_SET = 'DROPS_COLLECTION_NAME_SET';
export const DROPS_COLLECTION_THUMBNAIL_SET = 'DROPS_COLLECTION_THUMBNAIL_SET';
export const DROPS_COLLECTION_SYMBOL_SET = 'DROPS_COLLECTION_SYMBOL_SET';
export const DROPS_PRICE_PER_MINT_SET = 'DROPS_PRICE_PER_MINT_SET';
export const DROPS_MAX_MINTS_SET = 'DROPS_MAX_MINTS_SET';
export const DROPS_TOTAL_SUPPLY_SET = 'DROPS_TOTAL_SUPPLY_SET';
export const DROPS_SELECT_TOKEN_SET = 'DROPS_SELECT_TOKEN_SET';
export const DROPS_COLLECTION_ID_SET = 'DROPS_COLLECTION_ID_SET';
export const DROPS_COLLECT_MINT_REVENUE_SET = 'DROPS_COLLECT_MINT_REVENUE_SET';
export const DROPS_ADMIN_ADDRESS_SET = 'DROPS_ADMIN_ADDRESS_SET';
export const DROPS_DESCRIPTION_SET = 'DROPS_DESCRIPTION_SET';
export const DROPS_JSON_SCHEMA_SET = 'DROPS_JSON_SCHEMA_SET';
export const DROPS_SCHEMA_SET = 'DROPS_SCHEMA_SET';
export const INTERACT_TO_MINT_SWITCH_SET = 'INTERACT_TO_MINT_SWITCH_SET';

export const ADD_VIDEO_MINT_SET = 'ADD_VIDEO_MINT_SET';
export const FETCH_MY_IV_VIDEO_MINTS_SUCCESS = 'FETCH_MY_IV_VIDEO_MINTS_SUCCESS';
export const FETCH_MY_IV_VIDEO_MINTS_IN_PROGRESS = 'FETCH_MY_IV_VIDEO_MINTS_IN_PROGRESS';
export const FETCH_MY_IV_VIDEO_MINTS_ERROR = 'FETCH_MY_IV_VIDEO_MINTS_ERROR';

export const QUERY_CONTRACTS_CHANNEL_COLLECTION_SET = 'QUERY_CONTRACTS_CHANNEL_COLLECTION_SET';
export const QUERY_CONTRACTS_CHANNEL_CONFIG_SET = 'QUERY_CONTRACTS_CHANNEL_CONFIG_SET';
export const QUERY_CONTRACTS_CHANNEL_AUTH_DETAILS_SET = 'QUERY_CONTRACTS_CHANNEL_AUTH_DETAILS_SET';
export const QUERY_CONTRACTS_CHANNEL_TOKEN_DETAILS_SET = 'QUERY_CONTRACTS_CHANNEL_TOKEN_DETAILS_SET';

export const REMOVE_MINTER_CONTRACT_SUCCESS = 'REMOVE_MINTER_CONTRACT_SUCCESS';
export const REMOVE_MINTER_CONTRACT_IN_PROGRESS = 'REMOVE_MINTER_CONTRACT_IN_PROGRESS';
export const REMOVE_MINTER_CONTRACT_ERROR = 'REMOVE_MINTER_CONTRACT_ERROR';

export const SHOW_DROPS_LIST_EDIT_DIALOG = 'SHOW_DROPS_LIST_EDIT_DIALOG';
export const HIDE_DROPS_LIST_EDIT_DIALOG = 'HIDE_DROPS_LIST_EDIT_DIALOG';

export const SHOW_REMOVE_MINTER_CONTRACT_SUCCESS_DIALOG = 'SHOW_REMOVE_MINTER_CONTRACT_SUCCESS_DIALOG';
export const HIDE_REMOVE_MINTER_CONTRACT_SUCCESS_DIALOG = 'HIDE_REMOVE_MINTER_CONTRACT_SUCCESS_DIALOG';
export const HIDE_REMOVE_MINTER_CONTRACT_SUCCESS_SET = 'HIDE_REMOVE_MINTER_CONTRACT_SUCCESS_SET';

export const SUBSCRIBE_TOKEN_VALUE_SET = 'SUBSCRIBE_TOKEN_VALUE_SET';
export const SUBSCRIBE_AMOUNT_VALUE_SET = 'SUBSCRIBE_AMOUNT_VALUE_SET';
export const SHOW_SUBSCRIBE_MORE_DETAILS_DIALOG = 'SHOW_SUBSCRIBE_MORE_DETAILS_DIALOG';
export const HIDE_SUBSCRIBE_MORE_DETAILS_DIALOG = 'HIDE_SUBSCRIBE_MORE_DETAILS_DIALOG';
export const SUBSCRIBE_SWITCH_SET = 'SUBSCRIBE_SWITCH_SET';
export const SUBSCRIBE_ROYALTY_SET = 'SUBSCRIBE_ROYALTY_SET';
export const SUBSCRIBE_ROYALTY_SHARE_SET = 'SUBSCRIBE_ROYALTY_SHARE_SET';
export const SUBSCRIBE_TRANSFERABILITY_SET = 'SUBSCRIBE_TRANSFERABILITY_SET';
export const SUBSCRIBE_EXTENSIBILITY_SET = 'SUBSCRIBE_EXTENSIBILITY_SET';
export const SUBSCRIBE_NSFW_SET = 'SUBSCRIBE_NSFW_SET';
export const SUBSCRIBE_TOKEN_NAME_SET = 'SUBSCRIBE_TOKEN_NAME_SET';
export const SUBSCRIBE_NFT_MEDIA_SET = 'SUBSCRIBE_NFT_MEDIA_SET';
export const SUBSCRIBE_NFT_PREVIEW_SET = 'SUBSCRIBE_NFT_PREVIEW_SET';
export const SUBSCRIBE_TOTAL_NFT_S_SET = 'SUBSCRIBE_TOTAL_NFT_S_SET';
export const MINT_EDITION_NAME_SET = 'MINT_EDITION_NAME_SET';
export const MINT_EDITION_NFT_ID_SET = 'MINT_EDITION_NFT_ID_SET';
export const MINT_EDITION_DESCRIPTION_SET = 'MINT_EDITION_DESCRIPTION_SET';
export const MINT_EDITION_PREVIEW_URL_SET = 'MINT_EDITION_PREVIEW_URL_SET';
export const MINT_EDITION_SCHEMA_SET = 'MINT_EDITION_SCHEMA_SET';
export const MINT_EDITION_SYMBOL_SET = 'MINT_EDITION_SYMBOL_SET';

export const VIDEO_MINT_FIELDS_CLEAR = 'VIDEO_MINT_FIELDS_CLEAR';

export const VIDEO_MINT_IVS_FETCH_IN_PROGRESS = 'VIDEO_MINT_IVS_FETCH_IN_PROGRESS';
export const VIDEO_MINT_IVS_FETCH_SUCCESS = 'VIDEO_MINT_IVS_FETCH_SUCCESS';
export const VIDEO_MINT_IVS_FETCH_ERROR = 'VIDEO_MINT_IVS_FETCH_ERROR';

export const DELETE_IV_VIDEO_MINT_SUCCESS = 'DELETE_IV_VIDEO_MINT_SUCCESS';
export const DELETE_IV_VIDEO_MINT_IN_PROGRESS = 'DELETE_IV_VIDEO_MINT_IN_PROGRESS';
export const DELETE_IV_VIDEO_MINT_ERROR = 'DELETE_IV_VIDEO_MINT_ERROR';
export const SHOW_DELETE_IV_VIDEO_MINT_DIALOG = 'SHOW_DELETE_IV_VIDEO_MINT_DIALOG';
export const HIDE_DELETE_IV_VIDEO_MINT_DIALOG = 'HIDE_DELETE_IV_VIDEO_MINT_DIALOG';
export const HIDE_DELETE_IV_VIDEO_MINT_SUCCESS_SET = 'HIDE_DELETE_IV_VIDEO_MINT_SUCCESS_SET';

// publish video drop setup
export const PUBLISH_VIDEO_MINT_SETUP_SET = 'PUBLISH_VIDEO_MINT_SETUP_SET';

export const SELECT_VIDEO_DIALOG_TAB_SET = 'SELECT_VIDEO_DIALOG_TAB_SET';
export const DROPS_DIALOG_SEARCH_VALUE_SET = 'DROPS_DIALOG_SEARCH_VALUE_SET';

export const SHOW_DROP_PREVIEW_DIALOG = 'SHOW_DROP_PREVIEW_DIALOG';
export const HIDE_DROP_PREVIEW_DIALOG = 'HIDE_DROP_PREVIEW_DIALOG';
