import { ACTIVITY_URL, config, RUNNER_URL } from '../config';

const URL = config.API_URL;
const DATA_LAYER = config.DATA_LAYER;
const UPLOAD_URL = 'https://main-ipfs.omniflix.studio';

export const CONNECT_ACCOUNT_URL = `${URL}/users/connect-bc-account`;
export const ACCESS_TOKEN_URL = `${URL}/user/auth/refresh-token`;
export const ADD_TWITTER_ACCOUNT_URL = `${URL}/me/add-twitter-account`;
export const VERIFY_TWEET_URL = `${URL}/me/verify-twitter-account`;
export const PROFILE_DETAILS_URL = `${URL}/me/profile/details`;
export const CAMPAIGNS_URL = `${URL}/campaigns`;
export const IBC_TOKENS_LIST_URL = `${DATA_LAYER}/tokens`;
export const SCHEMA_LIST_URL = `${URL}/schemas`;
export const AVATAR_UPLOAD_URL = `${UPLOAD_URL}/api/v0/add?pin=true&rap-with-directory=false&progress=false`;
export const FEE_GRANT_ALLOWANCE_URL = `${URL}/me/fee-grant`;
export const PARAMS_URL = `${config.REST_URL}/omniflix/onft/v1beta1/params`;
export const ITC_PARAMS_URL = `${config.REST_URL}/omniflix/itc/v1/params`;
export const MARKETPLACE_PARAMS_URL = `${config.REST_URL}/omniflix/marketplace/v1beta1/params`;
export const ADD_INTERACTIVE_VIDEO_URL = `${URL}/interactive-videos`;
export const IV_STATS_URL = `${URL}/my/interactive-videos/stats`;
export const NETWORKS_URL = `${URL}/networks`;

// Assets
export const CATEGORY_URL = `${URL}/me/asset-categories`;
export const SOURCE_TYPE_URL = `${URL}/source-types`;
export const ADD_ASSETS_URL = `${URL}/me/assets`;
export const TAGS_URL = `${URL}/me/assets/tags`;
export const ASSET_CARDS_URL = `${URL}/me/stats`;
export const DOWNLOAD_NON_MINTED_ASSETS_URL = (format) => `${URL}/me/assets/export/non-minted?exportTo=${format}`;
export const urlUpdateInteractive = (interactiveVideoId) => `${URL}/interactive-videos/${interactiveVideoId}`;
export const urlAddInteraction = (interactiveVideoId) => `${URL}/interactive-videos/${interactiveVideoId}/interactions`;
export const urlDeleteInteraction = (interactiveVideoId, interactionID) => `${URL}/interactive-videos/${interactiveVideoId}/interactions/${interactionID}`;
export const urlFetchInteractiveVideo = (interactiveVideoId) => `${URL}/interactive-videos/${interactiveVideoId}`;
export const urlPublishInteractiveVideo = (interactiveVideoId) => `${URL}/interactive-videos/${interactiveVideoId}/publish`;
export const urlEnableInteractiveVideoMint = (interactiveVideoId) => `${URL}/interactive-videos/${interactiveVideoId}/enable-video-mint`;
export const urlEnablePublishInteractiveVideo = (interactiveVideoId) => `${URL}/interactive-videos/${interactiveVideoId}/enable`;
export const urlDisablePublishInteractiveVideo = (interactiveVideoId) => `${URL}/interactive-videos/${interactiveVideoId}/disable`;
export const urlFetchParticipationNftConfig = (interactiveVideoId) => `${URL}/interactive-videos/${interactiveVideoId}/participant-nft-config`;
export const PARTICIPATION_NFT_AUTHZ_ADDRESS_URL = `${URL}/get-participant-nfts-authz-account-address`;

export const urlCollectionStats = (address, denom) => {
    return `${DATA_LAYER}/stats?address=${address}&denomId=${denom}`;
};

export const urlFetchAssets = (skip, limit) => {
    const params = ['minted=false'];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/me/assets?${params.join('&')}`;
};

export const urlDownloadCollectionNFTs = (ID, format) => `${URL}/me/collections/${ID}/nft-export?exportTo=${format}`;
export const urlVerifyAccount = (userId) =>
    `${URL}/users/${userId}/verify-bc-account`;
export const urlAssetFetch = (ID) =>
    `${URL}/me/assets/${ID}`;
export const urlMintNft = (ID) =>
    `${URL}/me/assets/${ID}/nft/mint`;
export const urlCampaign = (ID) =>
    `${URL}/campaigns/${ID}/status`;
export const urlUploadAsset = (userId) =>
    `${RUNNER_URL}/users/${userId}/assets/file-upload`;
export const urlReUploadAsset = (userId, assetId) =>
    `${RUNNER_URL}/users/${userId}/assets/${assetId}/file-upload`;
export const urlOwnerCollections = (creator) => `${DATA_LAYER}/collections?creator=${creator}&sortBy=updated_at&limit=6`;
export const urlOwnerNFTs = (owner) => `${DATA_LAYER}/nfts?owner=${owner}&sortBy=updated_at&limit=6&listed=false`;
export const urlOwnerListings = (owner) => `${DATA_LAYER}/listings?owner=${owner}&sortBy=updated_at&limit=6`;

// User Activity
export const urlUserActivity = (address, skip, limit, sortBy, order) => {
    const params = [''];
    if (address) {
        params.push(`address=${address}`);
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }

    return `${ACTIVITY_URL}/activity?${params.join('&')}`;
};

export const urlCampaignLeaderBoard = (skip, limit, sortBy, order, search) => {
    const params = [''];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    }
    if (search) {
        params.push(`searchKey=${search}`);
    }

    return `${URL}/leaderboard?${params.join('&')}`;
};

// ITC Module
export const urlITCStats = (address) => `${DATA_LAYER}/stats/itc-campaigns?address=${address}`;
export const urlITCListings = (address, skip, limit, sortBy, order, status) => {
    const params = [];
    if (address) {
        params.push(`creator=${address}`);
    }
    if (sortBy) {
        params.push(`sortBy=${sortBy}`);
    } else {
        params.push('sortBy=created_at');
    }
    if (order) {
        params.push(`order=${order}`);
    } else {
        params.push('order=desc');
    }

    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (status) {
        params.push(`status=${status}`);
    }

    return `${DATA_LAYER}/itc-campaigns?${params.join('&')}`;
};

// IV
export const urlDownloadIVStats = (ID) => `${URL}/interactive-videos/${ID}/participants-answers?exportTo=csv`;

export const urlProposalsList = (restUrl) => `${restUrl}/cosmos/gov/v1/proposals?pagination.limit=1000&proposal_status=PROPOSAL_STATUS_VOTING_PERIOD&pagination.reverse=true`;
export const urlFetchNetworkDetails = (id) => `${URL}/networks/${id}`;
export const urlFetchProposalDetails = (restUrl, id) => `${restUrl}/cosmos/gov/v1/proposals/${id}`;

// Media Node
export const urlFetchMediaNodes = () => `${URL}/my/media-nodes`;
export const urlFetchMediaNodeDetails = (id) => `${URL}/my/media-nodes/${id}`;

export const urlAddMediaNode = () => `${URL}/my/media-nodes`;
export const urlDeleteMediaNode = (id) => `${URL}/my/media-nodes/${id}`;
