import { combineReducers } from 'redux';
import {
    IMAGE_URL_SET,
    INTEGRATION_ADD_ERROR,
    INTEGRATION_ADD_IN_PROGRESS,
    INTEGRATION_ADD_SUCCESS,
    INTEGRATION_DELETE_ERROR,
    INTEGRATION_DELETE_IN_PROGRESS,
    INTEGRATION_DELETE_SUCCESS,
    INTEGRATION_UPDATE_ERROR,
    INTEGRATION_UPDATE_IN_PROGRESS,
    INTEGRATION_UPDATE_SUCCESS,
    INTERACTION_MARK_SET,
    INTERACTIONS_LIST_SET,
    INTERACTIVE_ADDRESS_SET,
    INTERACTIVE_ANSWER_SET,
    INTERACTIVE_NETWORKS_SET,
    INTERACTIVE_OPTION_TEXT_SET,
    INTERACTIVE_OPTIONS_LIST_SET,
    INTERACTIVE_OPTIONS_SET,
    INTERACTIVE_PLACEHOLDER_SET,
    INTERACTIVE_PROPOSAL_NUMBER_SET,
    INTERACTIVE_QUESTION_SET,
    INTERACTIVE_REQUIRED_SET,
    INTERACTIVE_SEEK_TO_SET,
    INTERACTIVE_STATS_EXPORT_ACTIVITY_SET,
    INTERACTIVE_STATS_SEARCH_SET,
    INTERACTIVE_STATS_SORT_VALUE_SET,
    INTERACTIVE_TIME_SET,
    INTERACTIVE_TIMESTAMP_SET,
    INTERACTIVE_TYPE_DURATION_SET,
    INTERACTIVE_TYPE_SET,
    INTERACTIVE_VIDEO_ADD_ERROR,
    INTERACTIVE_VIDEO_ADD_IN_PROGRESS,
    INTERACTIVE_VIDEO_ADD_SUCCESS,
    INTERACTIVE_VIDEO_DELETE_ERROR,
    INTERACTIVE_VIDEO_DELETE_IN_PROGRESS,
    INTERACTIVE_VIDEO_DELETE_SUCCESS,
    INTERACTIVE_VIDEO_FETCH_ERROR,
    INTERACTIVE_VIDEO_FETCH_IN_PROGRESS,
    INTERACTIVE_VIDEO_FETCH_SUCCESS,
    INTERACTIVE_VIDEO_MINT_ENABLE_ERROR,
    INTERACTIVE_VIDEO_MINT_ENABLE_IN_PROGRESS,
    INTERACTIVE_VIDEO_MINT_ENABLE_SUCCESS,
    INTERACTIVE_VIDEO_PUBLISH_DISABLE_ERROR,
    INTERACTIVE_VIDEO_PUBLISH_DISABLE_IN_PROGRESS,
    INTERACTIVE_VIDEO_PUBLISH_DISABLE_SUCCESS,
    INTERACTIVE_VIDEO_PUBLISH_ENABLE_ERROR,
    INTERACTIVE_VIDEO_PUBLISH_ENABLE_IN_PROGRESS,
    INTERACTIVE_VIDEO_PUBLISH_ENABLE_SUCCESS,
    INTERACTIVE_VIDEO_PUBLISH_ERROR,
    INTERACTIVE_VIDEO_PUBLISH_IN_PROGRESS,
    INTERACTIVE_VIDEO_PUBLISH_SUCCESS,
    INTERACTIVE_VIDEO_UPDATE_ERROR,
    INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS,
    INTERACTIVE_VIDEO_UPDATE_SUCCESS,
    IV_DELETE_DIALOG_HIDE,
    IV_DELETE_DIALOG_SHOW,
    NEW_INTERACTIVE_TAB_SET,
    UNPUBLISH_DIALOG_SHOW,
    UNPUBLISH_DILOG_HIDE,
    VIDEO_MINT_SETUP_FROM_PUBLISH_PAGE_SET,
} from '../../constants/interactiveVideos/new';
import { durationOptions, typeOptions } from '../../utils/defaultOptions';
import { EMPTY_VALUES_SET } from '../../constants/createAssets';
import { EDIT_INTERACTION_CARD_HIDE } from '../../constants/interactiveVideos';

const newInteractiveTab = (state = 'edit', action) => {
    if (action.type === NEW_INTERACTIVE_TAB_SET) {
        return action.value;
    }

    return state;
};

const interactionsList = (state = [], action) => {
    if (action.type === INTERACTIONS_LIST_SET) {
        return action.value;
    } else if (action.type === INTERACTIVE_VIDEO_FETCH_SUCCESS) {
        return action.result && action.result.interactions;
    } else if (action.type === EMPTY_VALUES_SET) {
        return [];
    }

    return state;
};

const interactiveTime = (state = '00:00:00', action) => {
    if (action.type === INTERACTIVE_TIME_SET) {
        return action.value;
    }

    return state;
};

const interactiveType = (state = typeOptions && typeOptions[0] && typeOptions[0].value, action) => {
    if (action.type === INTERACTIVE_TYPE_SET) {
        return action.value;
    }

    return state;
};

const interactiveDuration = (state = durationOptions && durationOptions[1] && durationOptions[1].value, action) => {
    if (action.type === INTERACTIVE_TYPE_DURATION_SET) {
        return action.value;
    } else if (action.type === EDIT_INTERACTION_CARD_HIDE) {
        return durationOptions && durationOptions[1] && durationOptions[1].value;
    }

    return state;
};

const interactiveQuestion = (state = '', action) => {
    switch (action.type) {
    case INTERACTIVE_QUESTION_SET:
        return action.value;
    case EDIT_INTERACTION_CARD_HIDE:
        return '';
    default:
        return state;
    }
};

const interactivePlaceholder = (state = '', action) => {
    switch (action.type) {
    case INTERACTIVE_PLACEHOLDER_SET:
        return action.value;
    case EDIT_INTERACTION_CARD_HIDE:
        return '';
    default:
        return state;
    }
};

const interactiveAnswer = (state = '', action) => {
    switch (action.type) {
    case INTERACTIVE_ANSWER_SET:
        return action.value;
    case EDIT_INTERACTION_CARD_HIDE:
        return '';
    default:
        return state;
    }
};

const interactiveRequired = (state = false, action) => {
    switch (action.type) {
    case INTERACTIVE_REQUIRED_SET:
        return action.value;
    case EDIT_INTERACTION_CARD_HIDE:
        return false;
    default:
        return state;
    }
};

const interactiveOptions = (state = 'text', action) => {
    switch (action.type) {
    case INTERACTIVE_OPTIONS_SET:
        return action.value;
    case EDIT_INTERACTION_CARD_HIDE:
        return 'text';
    default:
        return state;
    }
};

const options = (state = [], action) => {
    switch (action.type) {
    case INTERACTIVE_OPTIONS_LIST_SET:
        return action.value;
    case EDIT_INTERACTION_CARD_HIDE:
        return [];
    default:
        return state;
    }
};

const interactiveOptionText = (state = '', action) => {
    switch (action.type) {
    case INTERACTIVE_OPTION_TEXT_SET:
        return action.value;
    case EDIT_INTERACTION_CARD_HIDE:
        return '';
    default:
        return state;
    }
};

const interactiveSeekTo = (state = {
    name: 'Timestamp',
    value: 'Timestamp',
}, action) => {
    if (action.type === INTERACTIVE_SEEK_TO_SET) {
        return action.value;
    }

    return state;
};

const interactiveTimestamp = (state = '00:00:00', action) => {
    if (action.type === INTERACTIVE_TIMESTAMP_SET) {
        return action.value;
    }

    return state;
};

const interactiveNetworks = (state = '', action) => {
    if (action.type === INTERACTIVE_NETWORKS_SET) {
        return action.value;
    }

    return state;
};

const interactiveAddress = (state = '', action) => {
    if (action.type === INTERACTIVE_ADDRESS_SET) {
        return action.value;
    }

    return state;
};

const interactiveProposalNumber = (state = '', action) => {
    if (action.type === INTERACTIVE_PROPOSAL_NUMBER_SET) {
        return action.value;
    }

    return state;
};

const statsSearch = (state = '', action) => {
    if (action.type === INTERACTIVE_STATS_SEARCH_SET) {
        return action.value;
    }

    return state;
};

const statsSort = (state = 'random', action) => {
    if (action.type === INTERACTIVE_STATS_SORT_VALUE_SET) {
        return action.value;
    }

    return state;
};

const statsExportActivity = (state = '', action) => {
    if (action.type === INTERACTIVE_STATS_EXPORT_ACTIVITY_SET) {
        return action.value;
    }

    return state;
};

const interactionMark = (state = {}, action) => {
    switch (action.type) {
    case INTERACTION_MARK_SET:
        return action.value;
    default:
        return state;
    }
};

const interactiveVideo = (state = {
    inProgress: false,
    value: {},
    result: {},
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEO_ADD_IN_PROGRESS:
    case INTERACTIVE_VIDEO_FETCH_IN_PROGRESS:
    case INTERACTIVE_VIDEO_UPDATE_IN_PROGRESS:
    case INTERACTIVE_VIDEO_DELETE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEO_ADD_SUCCESS:
    case INTERACTIVE_VIDEO_UPDATE_SUCCESS:
    case INTERACTIVE_VIDEO_DELETE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
            result: action.value,
        };
    case INTERACTIVE_VIDEO_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            result: action.result,
        };
    case INTERACTIVE_VIDEO_ADD_ERROR:
    case INTERACTIVE_VIDEO_FETCH_ERROR:
    case INTERACTIVE_VIDEO_UPDATE_ERROR:
    case INTERACTIVE_VIDEO_DELETE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case EMPTY_VALUES_SET:
        return {
            ...state,
            value: {},
            result: {},
        };
    default:
        return state;
    }
};

const interaction = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case INTEGRATION_ADD_IN_PROGRESS:
    case INTEGRATION_DELETE_IN_PROGRESS:
    case INTEGRATION_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTEGRATION_ADD_SUCCESS:
    case INTEGRATION_DELETE_SUCCESS:
    case INTEGRATION_UPDATE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case INTEGRATION_ADD_ERROR:
    case INTEGRATION_DELETE_ERROR:
    case INTEGRATION_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const publish = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case INTERACTIVE_VIDEO_PUBLISH_IN_PROGRESS:
    case INTERACTIVE_VIDEO_PUBLISH_ENABLE_IN_PROGRESS:
    case INTERACTIVE_VIDEO_PUBLISH_DISABLE_IN_PROGRESS:
    case INTERACTIVE_VIDEO_MINT_ENABLE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case INTERACTIVE_VIDEO_PUBLISH_SUCCESS:
    case INTERACTIVE_VIDEO_PUBLISH_ENABLE_SUCCESS:
    case INTERACTIVE_VIDEO_PUBLISH_DISABLE_SUCCESS:
    case INTERACTIVE_VIDEO_MINT_ENABLE_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case INTERACTIVE_VIDEO_PUBLISH_ERROR:
    case INTERACTIVE_VIDEO_PUBLISH_ENABLE_ERROR:
    case INTERACTIVE_VIDEO_PUBLISH_DISABLE_ERROR:
    case INTERACTIVE_VIDEO_MINT_ENABLE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const ivDeleteDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case IV_DELETE_DIALOG_SHOW:
        return {
            open: true,
        };
    case IV_DELETE_DIALOG_HIDE:
        return {
            open: false,
        };
    default:
        return state;
    }
};

const ImageUrl = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case IMAGE_URL_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const unpublishDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case UNPUBLISH_DIALOG_SHOW:
        return {
            open: true,
        };
    case UNPUBLISH_DILOG_HIDE:
        return {
            open: false,
        };
    default:
        return state;
    }
};

const publishVideoMintSetup = (state = false, action) => {
    switch (action.type) {
    case VIDEO_MINT_SETUP_FROM_PUBLISH_PAGE_SET:
        return action.value;
    case INTERACTIVE_VIDEO_PUBLISH_IN_PROGRESS:
    case INTERACTIVE_VIDEO_PUBLISH_ENABLE_IN_PROGRESS:
    case INTERACTIVE_VIDEO_PUBLISH_DISABLE_IN_PROGRESS:
    case INTERACTIVE_VIDEO_MINT_ENABLE_IN_PROGRESS:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    newInteractiveTab,
    interactionsList,
    interactiveTime,
    interactiveType,
    interactiveDuration,
    interactiveQuestion,
    interactivePlaceholder,
    interactiveAnswer,
    interactiveRequired,
    interactiveOptions,
    options,
    interactiveOptionText,
    interactiveSeekTo,
    interactiveTimestamp,
    interactiveNetworks,
    interactiveAddress,
    interactiveProposalNumber,

    statsSearch,
    statsSort,
    statsExportActivity,
    interactionMark,
    interactiveVideo,
    interaction,
    publish,
    ivDeleteDialog,
    ImageUrl,
    unpublishDialog,
    publishVideoMintSetup,
});
