import { combineReducers } from 'redux';
import {
    QUERY_CHANNEL_AUTH_DETAILS_ERROR,
    QUERY_CHANNEL_AUTH_DETAILS_IN_PROGRESS,
    QUERY_CHANNEL_AUTH_DETAILS_SUCCESS,
    QUERY_CHANNEL_COLLECTION_ERROR,
    QUERY_CHANNEL_COLLECTION_IN_PROGRESS,
    QUERY_CHANNEL_COLLECTION_SUCCESS,
    QUERY_CHANNEL_CONFIG_ERROR,
    QUERY_CHANNEL_CONFIG_IN_PROGRESS,
    QUERY_CHANNEL_CONFIG_SUCCESS,
    QUERY_CHANNEL_TOKEN_DETAILS_ERROR,
    QUERY_CHANNEL_TOKEN_DETAILS_IN_PROGRESS,
    QUERY_CHANNEL_TOKEN_DETAILS_SUCCESS,
    QUERY_CONTRACT_PARAMS_ERROR,
    QUERY_CONTRACT_PARAMS_IN_PROGRESS,
    QUERY_CONTRACT_PARAMS_SUCCESS,
} from '../../constants/queryContracts';

const collection = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case QUERY_CHANNEL_COLLECTION_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case QUERY_CHANNEL_COLLECTION_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case QUERY_CHANNEL_COLLECTION_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const tokenDetails = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case QUERY_CHANNEL_TOKEN_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case QUERY_CHANNEL_TOKEN_DETAILS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case QUERY_CHANNEL_TOKEN_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const config = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case QUERY_CHANNEL_CONFIG_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case QUERY_CHANNEL_CONFIG_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case QUERY_CHANNEL_CONFIG_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const authDetails = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case QUERY_CHANNEL_AUTH_DETAILS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case QUERY_CHANNEL_AUTH_DETAILS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case QUERY_CHANNEL_AUTH_DETAILS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const params = (state = {
    value: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case QUERY_CONTRACT_PARAMS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case QUERY_CONTRACT_PARAMS_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case QUERY_CONTRACT_PARAMS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    collection,
    tokenDetails,
    config,
    authDetails,
    params,
});
