import { combineReducers } from 'redux';
import language from './language';
import account from './account';
import snackbar from './snackbar';
import library from './library';
import createAssets from './createAssets';
import campaign from './campaign';
import faucet from './faucet';
import collection from './collection';
import activity from './activity';
import navBar from './navBar';
import filters from './filters';
import polls from './polls';
import auctions from './auctions';
import interactiveVideos from './interactiveVideos';
import params from './params';
import itcModule from './itcModule';
import channels from './channels';
import alertDialog from './alertDialog';
import authorization from './authorization';
import contractsList from './contractsList';
import mediaNode from './mediaNode';

export default combineReducers({
    language,
    account,
    snackbar,
    library,
    createAssets,
    campaign,
    faucet,
    collection,
    activity,
    navBar,
    filters,
    polls,
    auctions,
    interactiveVideos,
    params,
    itcModule,
    channels,
    alertDialog,
    authorization,
    contractsList,
    mediaNode,
});
