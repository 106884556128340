export const WINDOW_RESIZE_SET = 'WINDOW_RESIZE_SET';
export const LIBRARY_TAB_VALUE_SET = 'LIBRARY_TAB_VALUE_SET';

export const MENU_POPOVER_SHOW = 'MENU_POPOVER_SHOW';
export const MENU_POPOVER_HIDE = 'MENU_POPOVER_HIDE';

export const LIST_QUICK_VIEW_SHOW = 'LIST_QUICK_VIEW_SHOW';
export const LIST_QUICK_VIEW_HIDE = 'LIST_QUICK_VIEW_HIDE';

export const MENU_ACTION_DIALOG_SHOW = 'MENU_ACTION_DIALOG_SHOW';
export const MENU_ACTION_DIALOG_HIDE = 'MENU_ACTION_DIALOG_HIDE';

export const BURN_NFT_SET = 'BURN_NFT_SET';

export const ASSETS_FETCH_IN_PROGRESS = 'ASSETS_FETCH_IN_PROGRESS';
export const ASSETS_FETCH_SUCCESS = 'ASSETS_FETCH_SUCCESS';
export const ASSETS_FETCH_ERROR = 'ASSETS_FETCH_ERROR';

export const DOWNLOAD_NON_MINTED_ASSETS_IN_PROGRESS = 'DOWNLOAD_NON_MINTED_ASSETS_IN_PROGRESS';
export const DOWNLOAD_NON_MINTED_ASSETS_SUCCESS = 'DOWNLOAD_NON_MINTED_ASSETS_SUCCESS';
export const DOWNLOAD_NON_MINTED_ASSETS_ERROR = 'DOWNLOAD_NON_MINTED_ASSETS_ERROR';

export const BULK_EDIT_SHOW = 'BULK_EDIT_SHOW';
export const BULK_EDIT_HIDE = 'BULK_EDIT_HIDE';
export const BULK_EDIT_LIST_SET = 'BULK_EDIT_LIST_SET';

export const BULK_LIST_DIALOG_SHOW = 'BULK_LIST_DIALOG_SHOW';
export const BULK_LIST_DIALOG_HIDE = 'BULK_LIST_DIALOG_HIDE';

export const CONFIRM_BULK_LIST_DIALOG_SHOW = 'CONFIRM_BULK_LIST_DIALOG_SHOW';
export const CONFIRM_BULK_LIST_DIALOG_HIDE = 'CONFIRM_BULK_LIST_DIALOG_HIDE';

export const BULK_DE_LIST_DIALOG_SHOW = 'BULK_DE_LIST_DIALOG_SHOW';
export const BULK_DE_LIST_DIALOG_HIDE = 'BULK_DE_LIST_DIALOG_HIDE';

export const BULK_TRANSFER_DIALOG_SHOW = 'BULK_TRANSFER_DIALOG_SHOW';
export const BULK_TRANSFER_DIALOG_HIDE = 'BULK_TRANSFER_DIALOG_HIDE';
export const BULK_TRANSFER_ADDRESS_SET = 'BULK_TRANSFER_ADDRESS_SET';

export const BULK_BURN_DIALOG_SHOW = 'BULK_BURN_DIALOG_SHOW';
export const BULK_BURN_DIALOG_HIDE = 'BULK_BURN_DIALOG_HIDE';
export const BULK_BURN_ADDRESS_SET = 'BULK_BURN_ADDRESS_SET';

export const BULK_DELETE_DIALOG_SHOW = 'BULK_DELETE_DIALOG_SHOW';
export const BULK_DELETE_DIALOG_HIDE = 'BULK_DELETE_DIALOG_HIDE';

export const NON_MINTED_EXPORT_POPOVER_SHOW = 'NON_MINTED_EXPORT_POPOVER_SHOW';
export const NON_MINTED_EXPORT_POPOVER_HIDE = 'NON_MINTED_EXPORT_POPOVER_HIDE';
