import { combineReducers } from 'redux';
import { INSUFFICIENT_BALANACE_HIDE, INSUFFICIENT_BALANACE_SHOW } from '../constants/alertDialog';

const insufficientDialog = (state = {
    open: false,
}, action) => {
    switch (action.type) {
    case INSUFFICIENT_BALANACE_SHOW:
        return {
            open: true,
        };
    case INSUFFICIENT_BALANACE_HIDE:
        return {
            open: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    insufficientDialog,
});
