import { IPFS_GATEWAYS, IPFS_REFERENCE_PATH, IPFS_URL as URL } from '../config';

export const mediaReference = (link, gateWay) => {
    let IPFS_URL = gateWay ? String(gateWay)
        : (localStorage.getItem('of_studio_ipfs_url') && String(localStorage.getItem('of_studio_ipfs_url')));
    let hash = null;
    if (link && link.startsWith(IPFS_REFERENCE_PATH)) {
        IPFS_URL = gateWay ? String(gateWay) : String(URL);
        hash = link && link.replace(IPFS_REFERENCE_PATH, '');
    } else {
        const array = link && link.split('/');
        if (IPFS_URL && array && array.length && array[array.length - 2] &&
            (array[array.length - 2] === 'ipfs') && array[array.length - 1]) {
            hash = array[array.length - 1];
        } else if (IPFS_URL && array && array.length === 1) {
            hash = link;
        } else if (array && array.length === 1) {
            IPFS_URL = gateWay ? String(gateWay) : String(URL);
            hash = link;
        }
    }

    if (IPFS_URL && hash) {
        return `${IPFS_URL}/${hash}`;
    } else {
        return link;
    }
};

export const getAbsoluteHash = (link) => {
    let hash = null;
    if (link && link.startsWith(IPFS_REFERENCE_PATH)) {
        hash = link && link.replace(IPFS_REFERENCE_PATH, '');
    } else {
        const array = link && link.split('/');
        if (array && array.length && array[array.length - 2] &&
            (array[array.length - 2] === 'ipfs') && array[array.length - 1]) {
            hash = array[array.length - 1];
        } else if (array && array.length === 1) {
            hash = link;
        }
    }

    if (hash) {
        return `${IPFS_REFERENCE_PATH}${hash}`;
    } else {
        return link;
    }
};

export const hashReference = (link) => {
    const IPFS_URL = localStorage.getItem('of_studio_ipfs_url') && String(localStorage.getItem('of_studio_ipfs_url'));
    let hash = null;
    if (link && link.startsWith(IPFS_REFERENCE_PATH)) {
        hash = link && link.replace(IPFS_REFERENCE_PATH, '');
    } else {
        const array = link && link.split('/');
        if (IPFS_URL && array && array.length && array[array.length - 2] &&
            (array[array.length - 2] === 'ipfs') && array[array.length - 1]) {
            hash = array[array.length - 1];
        } else if (IPFS_URL && array && array.length === 1) {
            hash = link;
        } else if (array && array.length === 1) {
            hash = link;
        }
    }

    if (hash) {
        return hash;
    } else {
        return link;
    }
};

export const fetchMediaType = (mediaURI, cb) => {
    fetch(mediaURI).then((data) => {
        if (data) {
            const type = data.headers.get('content-type');
            cb(type);
            // data.blob().then((res) => {
            //     if (res && res.type) {
            //         cb(res.type);
            //     } else {
            //         cb(null);
            //     }
            // }).catch(() => {
            //     cb(null);
            // });
        } else {
            cb(null);
        }
    }).catch(() => {
        cb(null);
    });
};

export const setVideoSrcFallBack = (link) => {
    const array = [];

    IPFS_GATEWAYS.map((gateWay) => {
        const url = mediaReference(link, gateWay);
        array.push({
            type: 'video/mp4',
            src: url,
        });
    });

    return array;
};
