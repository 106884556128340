import {
    BID_PERCENTAGE_SET,
    CONFIRM_LIST_NFT_DIALOG_HIDE,
    CONFIRM_LIST_NFT_DIALOG_SHOW,
    DE_LISTED_DIALOG_HIDE,
    DE_LISTED_DIALOG_SHOW,
    END_DATE_SET,
    IBC_TOKENS_LIST_FETCH_ERROR,
    IBC_TOKENS_LIST_FETCH_IN_PROGRESS,
    IBC_TOKENS_LIST_FETCH_SUCCESS,
    LIST_NFT_DIALOG_HIDE,
    LIST_NFT_DIALOG_SHOW,
    LIST_NFT_SUCCESS_DIALOG_HIDE,
    LIST_NFT_SUCCESS_DIALOG_SHOW,
    LIST_TYPE_SET,
    LISTINGS_FETCH_ERROR,
    LISTINGS_FETCH_IN_PROGRESS,
    LISTINGS_FETCH_SUCCESS,
    SPLIT_INFO_SET,
    SPLIT_INFO_STATUS_SET,
    START_DATE_SET,
    WHITELIST_SWITCH_SET,
    WHITELIST_VALUE_SET,
} from '../../constants/createAssets/listing';
import { combineReducers } from 'redux';
import {
    BULK_LIST_DIALOG_HIDE,
    CONFIRM_BULK_LIST_DIALOG_HIDE,
    LIST_QUICK_VIEW_SHOW,
    MENU_POPOVER_SHOW,
} from '../../constants/library';
import { DEFAULT_LIMIT, DEFAULT_SEARCH, DEFAULT_SKIP } from '../../config';
import { EMPTY_VALUES_SET } from '../../constants/createAssets';

const dialog = (state = {
    open: false,
    value: {},
    success: false,
    cancelled: false,
    deList: false,
    info: {},
}, action) => {
    switch (action.type) {
    case LIST_NFT_DIALOG_SHOW:
        return {
            ...state,
            open: true,
            cancelled: false,
            value: action.value,
        };
    case LIST_NFT_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    case LIST_NFT_SUCCESS_DIALOG_SHOW:
        return {
            ...state,
            open: false,
            success: true,
            info: action.info,
            value: action.value ? action.value : state.value,
            deList: !!action.deList,
        };
    case LIST_NFT_SUCCESS_DIALOG_HIDE:
        return {
            ...state,
            success: false,
            info: {},
            value: {},
            deList: false,
        };
    case DE_LISTED_DIALOG_SHOW:
        return {
            ...state,
            success: false,
            cancelled: true,
            info: action.value,
            value: action.value && action.value.nft ? action.value.nft : action.value,
        };
    case DE_LISTED_DIALOG_HIDE:
        return {
            ...state,
            cancelled: false,
            info: {},
        };
    case MENU_POPOVER_SHOW:
    case LIST_QUICK_VIEW_SHOW:
        return {
            ...state,
            value: action.list ? action.value : state.value,
            info: action.list || {},
        };
    default:
        return state;
    }
};

const confirmListNFTDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CONFIRM_LIST_NFT_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case CONFIRM_LIST_NFT_DIALOG_HIDE:
    case LIST_NFT_DIALOG_HIDE:
    case LIST_NFT_SUCCESS_DIALOG_HIDE:
        return {
            ...state,
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const list = (state = {
    inProgress: false,
    value: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    search: DEFAULT_SEARCH,
    total: null,
}, action) => {
    switch (action.type) {
    case LISTINGS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LISTINGS_FETCH_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                value: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                search: action.search,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                value: [...state.value, ...action.value],
                skip: action.skip,
                limit: action.limit,
                search: action.search,
                total: action.total,
            };
        }
    case LISTINGS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const ibcTokensList = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case IBC_TOKENS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case IBC_TOKENS_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case IBC_TOKENS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const splitInfo = (state = {
    status: false,
    value: [],
}, action) => {
    switch (action.type) {
    case SPLIT_INFO_STATUS_SET: {
        if (action.value && state.value && Object.keys(state.value).length === 0) {
            return {
                status: action.value,
                value: [{
                    address: '',
                    weight: '',
                }],
            };
        } else if (!action.value) {
            return {
                status: action.value,
                value: [],
            };
        } else {
            return {
                ...state,
                status: action.value,
            };
        }
    }
    case SPLIT_INFO_SET:
        return {
            ...state,
            value: action.value,
        };
    case LISTINGS_FETCH_SUCCESS:
    case LIST_NFT_DIALOG_HIDE:
    case BULK_LIST_DIALOG_HIDE:
    case CONFIRM_BULK_LIST_DIALOG_HIDE:
        return {
            status: false,
            value: [],
        };
    default:
        return state;
    }
};

const bidPercentage = (state = '', action) => {
    switch (action.type) {
    case BID_PERCENTAGE_SET:
        return action.value;
    case LIST_NFT_DIALOG_HIDE:
    case BULK_LIST_DIALOG_HIDE:
    case CONFIRM_BULK_LIST_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

const whiteListSwitch = (state = false, action) => {
    switch (action.type) {
    case WHITELIST_SWITCH_SET:
        return action.value;
    case LIST_NFT_DIALOG_HIDE:
    case BULK_LIST_DIALOG_HIDE:
    case CONFIRM_BULK_LIST_DIALOG_HIDE:
        return false;
    default:
        return state;
    }
};

const whiteListValue = (state = [], action) => {
    switch (action.type) {
    case WHITELIST_VALUE_SET:
        return action.value;
    case LIST_NFT_DIALOG_HIDE:
    case BULK_LIST_DIALOG_HIDE:
    case CONFIRM_BULK_LIST_DIALOG_HIDE:
    case EMPTY_VALUES_SET:
        return [];
    default:
        return state;
    }
};

const startDate = (state = null, action) => {
    switch (action.type) {
    case START_DATE_SET:
        return action.value;
    case LIST_NFT_DIALOG_HIDE:
    case BULK_LIST_DIALOG_HIDE:
    case CONFIRM_BULK_LIST_DIALOG_HIDE:
        return null;
    default:
        return state;
    }
};

const endDate = (state = null, action) => {
    switch (action.type) {
    case END_DATE_SET:
        return action.value;
    case LIST_NFT_DIALOG_HIDE:
    case BULK_LIST_DIALOG_HIDE:
    case CONFIRM_BULK_LIST_DIALOG_HIDE:
        return null;
    default:
        return state;
    }
};

const listType = (state = 'fixed-price', action) => {
    switch (action.type) {
    case LIST_TYPE_SET:
        return action.value;
    case LIST_NFT_DIALOG_HIDE:
    case BULK_LIST_DIALOG_HIDE:
    case CONFIRM_BULK_LIST_DIALOG_HIDE:
        return 'fixed-price';
    default:
        return state;
    }
};

export default combineReducers({
    dialog,
    list,
    ibcTokensList,
    splitInfo,
    confirmListNFTDialog,
    bidPercentage,
    whiteListSwitch,
    whiteListValue,
    startDate,
    endDate,
    listType,
});
